import { createSlice } from '@reduxjs/toolkit';

const sampleSlice = createSlice({
    name: 'sample',
    initialState: {
        data: [],
    },
    reducers: {
        addData: (state, action) => {
            state.data.push(action.payload);
        },
    },
});

export const { addData } = sampleSlice.actions;
export default sampleSlice.reducer;
