import React, { useState, useEffect } from 'react';
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css";
import ForecastedChart from '../../components/DemandAnalysisComponents/ForecastedGraph/ForecastedChart';
import ForecastedData from '../../components/DemandAnalysisComponents/ForecastedTable/ForecastedData';
import DemandAnalysisHeader from '../../components/DemandAnalysisComponents/DemandAnalysisHeader';
import InSeasonAllocationHeader from '../../components/DemandAnalysisComponents/DemandAnalysisHeader';

import { demandAnalysisData, demandAnalysisPlot } from "../../features/DemandAnalysis/DemandAnalysisSlice";
import { useDispatch } from "react-redux";
import { levelDataApi } from '../../features/Filters/FiltersSlice';

function DemandAnalysisPage() {
    const dispatch = useDispatch();
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        dispatch(levelDataApi());
        dispatch(demandAnalysisData({ filter: "Set Filter", filterName: "" }));
        dispatch(demandAnalysisPlot({ filter: "Set Filter", filterName: "" }));
    }, [dispatch]);

    const handleReset = () => {
        setRefreshKey(prevKey => prevKey + 1); // Increment key to trigger re-render
    };

    return (
        <div className='dashboard_main_container'>
            <InSeasonAllocationHeader key= {refreshKey} onReset={handleReset} />
            <ForecastedChart key={refreshKey+1} />
            <ForecastedData key={refreshKey+2} />
        </div>
    );
}

export default DemandAnalysisPage;
