import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'antd';
import CardsHeading from '../../CommonComponents/CardsHeadingComponent';
import Graph from './ProductsMainPlot';
import '../../../css/DemandAnalysis/graphDemand.css'
import forecastedchartIcon from '../../../assets/SVG/icons/DemandAnalysis/forecastedcharticon.svg'
import { fetchChartData } from '../../../features/Product/ProductSlice';
import  CardsFilter  from '../../CommonComponents/CardsFilterComponent';

function ProductsGraph() {
    const dispatch = useDispatch();
    const { chartData, status } = useSelector((state) => state.products || {});
    const [loading, setLoading] = useState(true);

    const selectedItem = useSelector((state) => state.selectedItem);
    const selectedLocation = useSelector((state) => state.selectedLocation);
    

    useEffect(() => {
        dispatch(fetchChartData({ selectedItem, selectedLocation, }));
    }, [dispatch, selectedItem, selectedLocation,]);

    useEffect(() => {
        if (status === 'succeeded') {
            setLoading(false);
        }
    }, [status]);

    return (
        <Card
            className=' dashboard_card_containers'
            loading={loading}
            style={{ marginBottom: "20px" }}
        >
            <CardsHeading heading="Forecasted Chart" headingicon={forecastedchartIcon} showFilter={true} componentType="productGraph" />
            <div style={{ marginTop: "8px" }} className='best-selling-brands forecast-graph-filters-container'>
                <CardsFilter isProduct={true} />
            </div>
            {chartData && <Graph data={chartData} />}
        </Card>
    );
}

export default ProductsGraph;
