import React, { useState, useEffect, useRef } from 'react';
import { Card, Statistic, Row, Col, Divider } from 'antd';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import CardsHeading from '../CommonComponents/CardsHeadingComponent';
import { useSelector } from "react-redux";
function SalesAnalysis() {
    const [loading, setLoading] = useState(true);
    const { overallAnalysisData, salesAnalysisGrowthData } = useSelector((state) => state.dashboard || []);
    const chartRef = useRef(null);


    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    const ColorsPallate = ['#7B61FF', '#009EFD', '#7B61FF', '#00C6AE', '#FF4D4F']

    // const SalesAnalysisData = [
    //     { color: '#7B61FF', percent: '35%', name: 'Adidas' },
    //     { color: '#009EFD', percent: '25%', name: 'Nike' },
    //     { color: '#7B61FF', percent: '15%', name: 'Converse' },
    //     { color: '#00C6AE', percent: '5%', name: 'Vans Clothing' },
    //     { color: '#FF4D4F', percent: '2%', name: 'Reebok' },
    //     { color: '#A9A9A9', percent: '20%', name: 'Others' }
    // ]


    // Function to generate a random color
    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };


    // Function to assign colors dynamically
    const getSalesAnalysisData = (data) => {
        return data.map((item, index) => {
            const color = item.brand === "Others"
                ? '#A9A9A9'
                : (index < ColorsPallate.length
                    ? ColorsPallate[index % ColorsPallate.length]
                    : generateRandomColor());
            return {
                color: color,
                percent: item.contribution,
                name: item.brand
            };
        });
    };

    const SalesAnalysisData = getSalesAnalysisData(overallAnalysisData || []);
    const changePercent = parseFloat((salesAnalysisGrowthData?.change_percent || '0').replace('%', ''));

    // Determine the styles
    const backgroundColor = changePercent > 0 ? '#D9FFE4' : '#FFE8E8';
    const textColor = changePercent > 0 ? '#2AC239' : '#FF4444';
    return (
        <Card
            className='salesanalysis_main_container dashboard_card_containers'
            loading={loading}
        >
            <CardsHeading heading="Overall Sales Analysis" dataTODownload={chartRef} showFilter={true} componentType="salesanalysis" />
            <div className="sales-overview">
                <Row gutter={20}>
                    <Col span={14}>
                        <Statistic
                            title=""
                            value={salesAnalysisGrowthData?.sale_value}
                            precision={1}
                            valueStyle={{
                                color: '#288CD5', fontSize: '50px', fontWeight: "600"
                            }}
                        />
                    </Col>
                    <Col span={10} className='sales-overview-percentage'>
                        <Statistic
                            title=""
                            value={salesAnalysisGrowthData?.change_percent}
                            precision={2}
                            valueStyle={{ background: backgroundColor, color: textColor, fontWeight: "600" }}
                            suffix={<span className='sales-overview-percentage-suffix'>
                                {changePercent > 0 ? <RiseOutlined /> : <FallOutlined />}
                            </span>
                            }
                        />
                    </Col>

                </Row>
            </div>

            <div className="statistic-title">{salesAnalysisGrowthData?.heading}</div>
            <Divider />
            <div ref={chartRef} className="best-selling-brands">

                <h3>Best selling brands</h3>

                <div className="brand-details">
                    {SalesAnalysisData.map((brand, index) => (
                        <div key={index} className="brand" style={{ width: brand.percent }}>
                            <span className="brand-percent" style={{ color: brand.color }}>{brand.percent}</span>
                            <div className="brand-bar" style={{ backgroundColor: brand.color }} />
                        </div>
                    ))}

                </div>
                <div className="brand-details-data">
                    {SalesAnalysisData.map((brand, index) => (
                        <div key={index} className="brand">
                            <span className="brand-name" style={{ color: brand.color }}>
                                <span className="bullet" style={{ backgroundColor: brand.color }}></span>
                                {brand.name}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </Card>
    );
}

export default SalesAnalysis;
