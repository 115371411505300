import React, { useState, useEffect } from 'react';

import clockIcon from '../../../assets/SVG/icons/DemandAnalysis/clock-icon.svg'
import CardsFilter from '../../CommonComponents/CardsFilterComponent';
import GraphFilters from './GraphFilters';
function ForecastGraphFilters({ handleSpecialDayHover }) {
    const [loading, setLoading] = useState(true);


    return (
        <div style={{ marginTop: "8px" }} className='best-selling-brands forecast-graph-filters-container'>
            <CardsFilter isAssortment={false} />
            <GraphFilters handleSpecialDayHover={handleSpecialDayHover} />
        </div>
    );
}

export default ForecastGraphFilters;
