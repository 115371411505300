// FullscreenModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import Chart from 'react-apexcharts';
const FullscreenModal = ({ visible, onClose, title, options, series, type, height }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (visible) {
            setLoading(true);
            // Simulate chart loading time, or you can use actual events if Chart library supports
            setTimeout(() => setLoading(false), 1000); // Adjust the timeout as needed
        }
    }, [visible]);

    return (

        <Modal
            title={title}
            visible={visible}
            onCancel={onClose}
            footer={null}
            width="80%"
            // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            bodyStyle={{ padding: 0, height: '55vh', overflow: 'hidden' }}
        >
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <Chart options={options} series={series} type={type} height={height} />
            )}
        </Modal>
    );
};

export default FullscreenModal;
