import React, { useEffect } from 'react';
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css";
import { useDispatch, useSelector } from "react-redux";
import ForecastSetupTable from '../../components/ForecastSetupComponents/FOrecastSetupTable';

import { forecastSetupApi } from "../../features/ForecastSetup/ForecastSetupSlice";
function ForecastSetupPage() {

    const dispatch = useDispatch();

    useEffect(() => {

        // if (status === 'idle') {
        dispatch(forecastSetupApi(''));
        // }
    }, [dispatch]);

    // console.log('Forecast Demand Analysis Data:', forecastDemandAnalysisData); // Log the forecast data

    return (
        <div className='dashboard_main_container'>
            <ForecastSetupTable />
        </div>
    );
}

export default ForecastSetupPage;
