import React from 'react';
import Chart from 'react-apexcharts';

function LineChart({ row, col }) {
    const options = {
        chart: {
            type: 'line',
            zoom: {
                enabled: true
            }
        },
        stroke: {
            curve: 'straight'
        },
        xaxis: {
            categories: row,
        },
        tooltip: {
            enabled: true,
            x: {
                show: true
            }
        }
    };

    // Ensure col (series) is in the correct format
    const series = [{
        data: col
    }];

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
            width: '70%',
        }}>
            <div style={{
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                padding: '20px',
                width: '100%', // Adjust width as needed
                height: '70%', // Adjust height as needed
            }}>
                <Chart options={options} series={series} type="line" width="90%" />
            </div>
        </div>
    );
}

export default LineChart;
