import React, { useState, useEffect } from 'react';
import { Card} from 'antd';
import '../../../css/DemandAnalysis/graphDemand.css'
import CardsHeading from '../../CommonComponents/CardsHeadingComponent';
import AssortmentPlot from './AssortmentPlot';
import AssortedIcon from "../../../assets/SVG/icons/Assortment/bx_line-chart.png"
import CardsFilter from '../../CommonComponents/CardsFilterComponent';
function AssortmentGraphMain() {
    const [loading, setLoading] = useState(true);
   
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Card
            className=' dashboard_card_containers'
            loading={loading}
            style={{ marginBottom: "20px" }}

        >
            <CardsHeading heading="Assortment Planning - Chart" headingicon={AssortedIcon} showFilter={true} componentType="assortGraph" />

            <div style={{ marginTop: "8px" }} className='best-selling-brands forecast-graph-filters-container'>
                <CardsFilter isAssortment={true} />
            </div>

            <AssortmentPlot />
        </Card>
    );
}

export default AssortmentGraphMain;
