import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card, Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import FullscreenModal from '../../CommonComponents/FullscreenModal';

const signaporeHolidaysData = [
    {
        "date": "2024-01-01",
        "day": "Monday",
        "holiday_name": "New Year",
        "description": "New Year",
        "is_holiday": true,
    },
    {
        "date": "2024-02-11",
        "day": "Sunday",
        "holiday_name": "Chinese New Year",
        "description": "Chinese New Year",
        "is_holiday": true
    },
    {
        "date": "2024-03-29",
        "day": "Friday",
        "holiday_name": "Good Friday",
        "description": "Good Friday",
        "is_holiday": true
    },
    {
        "date": "2024-04-10",
        "day": "Wednesday",
        "holiday_name": "Hari Raya Puasa",
        "description": "Hari Raya Puasa",
        "is_holiday": true
    },
    {
        "date": "2024-05-01",
        "day": "Wednesday",
        "holiday_name": "Labour Day",
        "description": "Labour Day",
        "is_holiday": true
    },
    {
        "date": "2024-05-22",
        "day": "Wednesday",
        "holiday_name": "Vesak Day",
        "description": "Vesak Day",
        "is_holiday": true
    },
    {
        "date": "2024-06-17",
        "day": "Monday",
        "holiday_name": "Hari Raya Haji",
        "description": "Hari Raya Haji",
        "is_holiday": true
    },
    {
        "date": "2024-08-09",
        "day": "Friday",
        "holiday_name": "National Day",
        "description": "National Day",
        "is_holiday": true
    },
    {
        "date": "2024-10-31",
        "day": "Thursday",
        "holiday_name": "Diwali",
        "description": "Diwali",
        "is_holiday": true
    },
    {
        "date": "2024-12-25",
        "day": "Wednesday",
        "holiday_name": "Christmas",
        "description": "Christmas Day",
        "is_holiday": true
    }
];




// Define your colors based on holiday names or any criteria
const holidayColors = {
    'Chinese New Year': '#2AC2394D',
    'New Year': '#00C1F9',
    'Good Friday': '#00C1F9',
    'Christmas': '#215CA24D',
    'Hari Raya Puasa': '#FF45EC4D',
    "Labour Day": '#e4afb1',
    "Vesak Day": '#A6D21287',
    "Hari Raya Haji": '#B2ACD6',
    "National Day": '#B24CD6',
    "Diwali": '#B3BC46',
    "Prophet's Ascension": '#DAD7D7',
    "Prophet's Birthday": '#FD98E7',
    "Ramadan": '#F4F0F0',
    "CNY": '#ff927f',
    "Eid al-Fitr": '#E9D2D2',
    "Day of Arafat/ EID": '#FAEBEB',
    "Islamic New Year": '#FCE8E8',
    "Commemoration Day & National Day": '#F5F0F0',
    "Qatar National Day": '#F3E6F1',
    "Qatar National Sports Day": '#EFE6F0',
    "National Day/Liberation Day": '#F7F0EE',
};

const getRandomColor = (holidayName) => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
};

const getHolidayColor = (holidayName) => {
    const singaporeHoliday = signaporeHolidaysData.find(holiday => holiday.description === holidayName);
    return singaporeHoliday ? holidayColors[singaporeHoliday.holiday_name] : holidayColors[holidayName] || '#dbcd9e';
};

// Sample data transformation function
// Sample data transformation function
// const processHolidayData = (data) => {
//     const specialDaysData = {};

//     data?.forEach(entry => {
//         if (entry.is_holiday) {
//             const holidayName = entry.holiday_name || 'Unknown Holiday'; // handle entries without holiday names
//             if (!specialDaysData[holidayName]) {
//                 specialDaysData[holidayName] = {
//                     name: holidayName,
//                     dates: [],
//                     color: holidayColors[holidayName] || '#dbcd9e' // default color if holiday name is not found
//                 };
//             }
//             specialDaysData[holidayName].dates.push(entry.date);
//         }
//     });

//     return Object.values(specialDaysData);
// };


const processHolidayData = (data) => {
    const specialDaysData = {};

    data?.forEach(entry => {
        if (entry.is_holiday) {
            const holidayNames = entry.holiday_name.split(',');
            holidayNames.forEach(holidayName => {
                holidayName = holidayName.trim();
                if (!specialDaysData[holidayName]) {
                    specialDaysData[holidayName] = {
                        name: holidayName,
                        dates: [],
                        color: getHolidayColor(holidayName)
                    };
                }
                specialDaysData[holidayName].dates.push(entry.date);
            });
        }
    });

    return Object.values(specialDaysData);
};

const Graph = ({ highlightedDay }) => {
    const { status, countryFilter, forecastDemandAnalysisPlot } = useSelector((state) => state.demandAnalysis || {});
    const [historicalData, setHistoricalData] = useState([]);
    const [mlForecasted, setMlForecasted] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Async function to fetch and process the data
    const fetchData = async () => {
        // Ensure that the plot data is populated before proceeding
        if (forecastDemandAnalysisPlot?.actual && forecastDemandAnalysisPlot?.forecast) {
            setHistoricalData(forecastDemandAnalysisPlot.actual);
            setMlForecasted(forecastDemandAnalysisPlot.forecast);
            setIsDataReady(true); // Mark data as ready
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data once the component mounts or when forecastDemandAnalysisPlot changes
    }, [forecastDemandAnalysisPlot]);

    // Series data is only populated when isDataReady is true
    const series = isDataReady ? [
        {
            name: 'Actual',
            data: historicalData.map(item => ({
                x: item.report_date,
                y: item.sales !== 0 ? item.sales : null
            }))
        },
        {
            name: 'Bestfit Forecast',
            data: mlForecasted.map(item => ({
                x: item.report_date,
                y: item.Bestfit_Forecast !== 0 ? item.Bestfit_Forecast : null
            }))
        },
        {
            name: 'Prophet',
            data: mlForecasted.map(item => ({
                x: item.report_date,
                y: item.prophet_forecast !== 0 ? item.prophet_forecast : null
            }))
        },
        {
            name: 'ETS',
            data: mlForecasted.map(item => ({
                x: item.report_date,
                y: item.ets_forecast !== 0 ? item.ets_forecast : null
            }))
        }
    ] : [];

    // Chart options
    const options = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true
            },
            toolbar: {
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: [
                        {
                            icon: '<span>&#x26F6;</span>', // Fullscreen icon
                            index: 4,
                            title: 'Fullscreen',
                            class: 'custom-fullscreen',
                            click: function (chart, options, e) {
                                setIsModalVisible(true);
                            }
                        }
                    ]
                }
            }
        },
        colors: ['#7B61FF', '#FF4444', '#2AC239'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [3, 3, 3],
            curve: 'straight',
            dashArray: [0, 8, 8]
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'MMM yyyy'
            }
        },
        yaxis: {
            title: {
                text: 'Sales'
            },
            labels: {
                formatter: function (value) {
                    return value / 1000 + 'K';
                }
            }
        }
    };

    return (
        <div>
            {status === 'loading' || !isDataReady ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Chart options={options} series={series} type="line" height={350} />
                    <FullscreenModal
                        visible={isModalVisible}
                        onClose={() => setIsModalVisible(false)}
                        title="Forecasted Graph"
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                    />
                </>
            )}
        </div>
    );
};

export default Graph;