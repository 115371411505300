import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

// const buildQueryParams = (state, exclude = []) => {
//     let queryParams = [];

//     if (!exclude.includes('filter') && state.filter !== 'Set Filter') {
//         queryParams.push(`filter_type=${state.filter}`);
//     }
//     if (!exclude.includes('brandFilters') && state.brandFilters.length > 0 && state.brandFilters[0] !== "All") {
//         queryParams.push(...state.brandFilters.map(item => `brand=${encodeURIComponent(item)}`));
//     }
//     if (!exclude.includes('countryFilter') && state.countryFilter.length > 0 && state.countryFilter[0] !== "All") {
//         queryParams.push(...state.countryFilter.map(item => `country=${encodeURIComponent(item)}`));
//     }
//     if (!exclude.includes('categoryFilters') && state.categoryFilters.length > 0 && state.categoryFilters[0] !== "All") {
//         queryParams.push(...state.categoryFilters.map(item => `category=${encodeURIComponent(item.toLowerCase())}`));
//     }
//     if (!exclude.includes('genderFilters') && state.genderFilters.length > 0 && state.genderFilters[0] !== "All") {
//         queryParams.push(...state.genderFilters.map(item => `gender=${encodeURIComponent(item.toLowerCase())}`));
//     }
//     if (!exclude.includes('levelFilter')) {
//         queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
//     }
//     if (!exclude.includes('season') && state.season !== 'All') {
//         queryParams.push(`season=${encodeURIComponent(state.season)}`);
//     }
//     if (!exclude.includes('divisionFilter') && state.divisionFilter.length > 0 && state.divisionFilter[0] !== "All") {
//         queryParams.push(...state.divisionFilter.map(item => `division=${encodeURIComponent(item)}`));
//     }

//     return queryParams.join('&');
// };



const buildQueryParams = (state, globalFilters) => {
    let queryParams = [];

    // Loop through globalFilters dynamically
    for (const [key, value] of Object.entries(globalFilters)) {
        if (Array.isArray(value)) {
            // Handle array values by appending each item with the corresponding key
            queryParams.push(...value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item.toLowerCase())}`));
        } else {
            // If it's not an array, just append the key and value pair
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toLowerCase())}`);
        }
    }

    // Existing state filters
    if (state.genderFilter && state.genderFilter.length > 0 && state.genderFilter[0] !== "All") {
        queryParams.push(...state.genderFilter.map(item => `gender=${encodeURIComponent(item.toLowerCase())}`));
    }

    if (state.levelFilter) {
        queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
    }

    return queryParams.join('&');
};

export const assortmentTableDataApi = createAsyncThunk(
    'assortment/getTableData',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = "";

        switch (params.filterName) {
            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "brand":
                thunkAPI.dispatch(setBrandFilter(params.selectedItems));
                break;
            case "country":
                thunkAPI.dispatch(setCountryFilter(params.selectedItems));
                break;
            case "category":
                thunkAPI.dispatch(setCategoryFilter(params.selectedItems));
                break;
            case "season":
                thunkAPI.dispatch(setSeasonFilter(params.selectedItems));
                break;
            case "gender":
                thunkAPI.dispatch(setGenderFilter(params.selectedItems));
                break;
            case "division":
                thunkAPI.dispatch(setDivisionFilter(params.selectedItems));
                break;
            default:
                thunkAPI.dispatch(setFilter('Set Filter'));
                thunkAPI.dispatch(setBrandFilter([]));
                thunkAPI.dispatch(setCountryFilter([]));
                thunkAPI.dispatch(setCategoryFilter([]));
                thunkAPI.dispatch(setGenderFilter([]));
                thunkAPI.dispatch(setSeasonFilter([]));
                thunkAPI.dispatch(setDivisionFilter([]));
                break;
        }

        const state = thunkAPI.getState();
        const globalFilter = state.filter.globalFilters
        // joint = buildQueryParams(state.assortment, ['levelFilter']);
        joint = buildQueryParams(state.assortment, globalFilter);

        endpoint = `/customer/assortment_planning_table/?${joint}`;
        const request = await makeRequest("get", endpoint);
        const response = await request.data;
        return response;
    }
);

export const assortmentPlotApi = createAsyncThunk(
    'assortment/getPlotData',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = "";

        switch (params.filterName) {
            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "brand":
                thunkAPI.dispatch(setBrandFilter(params.selectedItems));
                break;
            case "country":
                thunkAPI.dispatch(setCountryFilter(params.selectedItems));
                break;
            case "category":
                thunkAPI.dispatch(setCategoryFilter(params.selectedItems));
                break;
            case "season":
                thunkAPI.dispatch(setSeasonFilter(params.selectedItems));
                break;
            case "gender":
                thunkAPI.dispatch(setGenderFilter(params.selectedItems));
                break;
            case "division":
                thunkAPI.dispatch(setDivisionFilter(params.selectedItems));
                break;
            default:
                thunkAPI.dispatch(setFilter('Set Filter'));
                thunkAPI.dispatch(setBrandFilter([]));
                thunkAPI.dispatch(setCountryFilter([]));
                thunkAPI.dispatch(setCategoryFilter([]));
                thunkAPI.dispatch(setGenderFilter([]));
                thunkAPI.dispatch(setSeasonFilter([]));
                thunkAPI.dispatch(setDivisionFilter([]));
                break;
        }

        const state = thunkAPI.getState();
        const globalFilter = state.filter.globalFilters
        // joint = buildQueryParams(state.assortment, ['levelFilter']);
        joint = buildQueryParams(state.assortment, globalFilter);

        endpoint = `customer/assortment_planning_chart/?${joint}`;
        const request = await makeRequest("get", endpoint);
        const response = await request.data;
        return response;
    }
);

const assortmentSlice = createSlice({
    name: "assortment",
    initialState: {
        status: 'idle',
        filter: 'Set Filter',
        brandFilters: ["All"],
        countryFilter: ["All"],
        levelFilter: "Monthly",
        categoryFilters: ["All"],
        genderFilters: ["All"],
        season: ["All"],
        divisionFilter: ["All"],
        assortmentTableData: [],
        assortmentPlot: [],
        assortmentPlotMainData: [],
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setBrandFilter: (state, action) => {
            state.brandFilters = action.payload;
        },
        setCountryFilter: (state, action) => {
            state.countryFilter = action.payload;
        },
        setLevelFilter: (state, action) => {
            state.levelFilter = action.payload;
        },
        setCategoryFilter: (state, action) => {
            state.categoryFilters = action.payload;
        },
        setGenderFilter: (state, action) => {
            state.genderFilters = action.payload;
        },
        setSeasonFilter: (state, action) => {
            state.season = action.payload;
        },
        setDivisionFilter: (state, action) => {
            state.divisionFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(assortmentTableDataApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(assortmentTableDataApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assortmentTableData = action.payload;
            })
            .addCase(assortmentTableDataApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(assortmentPlotApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(assortmentPlotApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assortmentPlot = action.payload;

                const groupedData = action.payload.reduce((acc, curr) => {
                    if (!acc[curr.location]) {
                        acc[curr.location] = [];
                    }
                    acc[curr.location].push(curr);
                    return acc;
                }, {});

                const mainData = Object.keys(groupedData).map(location => ({
                    name: location,
                    data: groupedData[location].map(item => ({
                        x: item.total_rate_of_sale,
                        y: item.location
                    })),
                    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
                }));
                state.assortmentPlotMainData = mainData;
            })
            .addCase(assortmentPlotApi.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setFilter, setBrandFilter, setCountryFilter, setLevelFilter, setCategoryFilter, setGenderFilter, setSeasonFilter, setDivisionFilter } = assortmentSlice.actions;
export default assortmentSlice.reducer;
