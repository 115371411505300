import React from 'react';
import { Modal, Button, Select } from 'antd';
import { ReactComponent as GraphIcon } from '../assets/SVG/icons/MyCharts/bar.svg';
import { ReactComponent as LineChartIcon } from '../assets/SVG/icons/MyCharts/line.svg'; 
import { ReactComponent as PieChartIcon } from '../assets/SVG/icons/MyCharts/pie.svg';
import Group66 from '../assets/Images/Group66.png'; // Adjust image path as necessary

const { Option } = Select;

function ChartSettingsModal({ visible, onCancel, chartType }) {
    const handleBack = () => {
        // Implement the logic to go back to the previous state or modal
        onCancel(); // Example action
    };

    const handleCreate = () => {
        // Implement the logic to create the graph based on the settings
        console.log("Create graph logic goes here");
    };

    const renderContent = () => {
        switch (chartType) {
            case 'bar chart':
                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                            <GraphIcon style={{ height: '1.5rem', marginRight: '0.2rem', color: '#7B61FF' }} />
                            <p style={{ color: '#7B61FF', fontSize: '14px', margin: 0 }}>
                                Set Parameters for <b>Bar Chart</b>
                            </p>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Y-axis (Vertical Line)</p>
                            <p style={{ color: 'grey', marginBottom: '0.5rem', fontSize: '10px' }}>Select a parameter to display on the y-axis of the Bar chart.</p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="x1"
                            >
                                <Option value="x1">item_sku(count)</Option>
                                <Option value="x2">report_date</Option>
                                <Option value="x3">total_sales</Option>
                            </Select>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>X-axis (Horizontal Line)</p>
                            <p style={{ color: 'grey', marginBottom: '0.5rem', fontSize: '10px' }}>Select a parameter to display on the x-axis of the Bar chart.</p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="y1"
                            >
                                <Option value="y1">report_date</Option>
                                <Option value="y2">item_sku(count)</Option>
                                <Option value="y3">total_sales</Option>
                            </Select>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Select Duration</p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="3months"
                            >
                                <Option value="3months">Past 3 months</Option>
                                <Option value="6months">Past 6 months</Option>
                                <Option value="9months">Past 9 months</Option>
                            </Select>
                            <p style={{ color: '#7B61FF', fontSize: '10px', marginTop: '0.5rem' }}>
                                *The displayed durations are based on the available data in the dataset. Please update the data if you need additional variations.
                            </p>
                        </div>
                    </>
                );
            case 'line chart':
                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                            <LineChartIcon style={{ height: '1.5rem', marginRight: '0.2rem', color: '#7B61FF' }} />
                            <p style={{ color: '#7B61FF', fontSize: '14px', margin: 0 }}>
                                Set Parameters for <b>Line Chart</b>
                            </p>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Y-axis (Vertical Line)</p>
                            <p style={{ color: 'grey', marginBottom: '0.5rem', fontSize: '10px' }}>
                                Select a parameter to display on the y-axis of the Line chart.
                            </p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="x1"
                            >
                                <Option value="x1">item_sku(count)</Option>
                                <Option value="x2">report_date</Option>
                                <Option value="x3">total_sales</Option>
                            </Select>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>X-axis (Horizontal Line)</p>
                            <p style={{ color: 'grey', marginBottom: '0.5rem', fontSize: '10px' }}>
                                Select a parameter to display on the x-axis of the Line chart.
                            </p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="y1"
                            >
                                <Option value="y1">report_date</Option>
                                <Option value="y2">item_sku(count)</Option>
                                <Option value="y3">total_sales</Option>
                            </Select>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Select Duration</p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="3months"
                            >
                                <Option value="3months">Past 3 months</Option>
                                <Option value="6months">Past 6 months</Option>
                                <Option value="9months">Past 9 months</Option>
                            </Select>
                            <p style={{ color: '#7B61FF', fontSize: '10px', marginTop: '0.5rem' }}>
                                *The displayed durations are based on the available data in the dataset. Please update the data if you need additional variations.
                            </p>
                        </div>
                    </>
                );
            case 'pie chart':
                return (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                            <PieChartIcon style={{ height: '1.5rem', marginRight: '0.2rem', color: '#7B61FF' }} />
                            <p style={{ color: '#7B61FF', fontSize: '14px', margin: 0 }}>
                                Set Parameters for <b>Pie Chart</b>
                            </p>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Category/legend</p>
                            <p style={{ color: 'grey', marginBottom: '0.5rem', fontSize: '10px' }}>Classify data by selecting category.</p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="x1"
                            >
                                <Option value="x1">item_sku(count)</Option>
                                <Option value="x2">report_date</Option>
                                <Option value="x3">total_sales</Option>
                            </Select>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Value</p>
                            <p style={{ color: 'grey', marginBottom: '0.5rem', fontSize: '10px' }}>Select the values you want to display.</p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="y1"
                            >
                                <Option value="y1">report_date</Option>
                                <Option value="y2">item_sku(count)</Option>
                                <Option value="y3">total_sales</Option>
                            </Select>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0.5rem' }}>Select Duration</p>
                            <Select
                                style={{ width: '100%' }}
                                defaultValue="3months"
                            >
                                <Option value="3months">Past 3 months</Option>
                                <Option value="6months">Past 6 months</Option>
                                <Option value="9months">Past 9 months</Option>
                            </Select>
                            <p style={{ color: '#7B61FF', fontSize: '10px', marginTop: '0.5rem' }}>
                                *The displayed durations are based on the available data in the dataset. Please update the data if you need additional variations.
                            </p>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            visible={visible}
            title={(
                <span style={{ display: "flex", gap: "1rem" }}>
                    <img style={{ height: "3.3rem" }} src={Group66} alt="Chart Icon" />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ color: "#288CD5", fontSize: '18px', fontWeight: 'bold' }}>
                            Creating Chart - Final Step
                        </div>
                        <p style={{ color: "#919191", fontSize: "12px", fontWeight: "400", lineHeight: "19.36px" }}>
                            Design a chart tailored to your needs to monitor the specific data you want to track.
                        </p>
                    </div>
                </span>
            )}
            onCancel={onCancel}
            footer={
                <>
                    <Button onClick={handleBack} style={{ backgroundColor: '#B0B0B0', color: 'white' }}>
                        Back
                    </Button>
                    <Button onClick={handleCreate} type="primary">
                        Create
                    </Button>
                </>
            }
            width={600}
        >
            {renderContent()}
        </Modal>
    );
}

export default ChartSettingsModal;
