import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import ForecastingSetupModal from '../ForecastSetupComponents/ForecastSetupModal/ForecastingSetupModal';
import { useSelector, useDispatch } from 'react-redux';

// Import icons 
import DashboardIcon from '../../assets/SVG/icons/Sidebar/dashboard.svg';
import DashboardHoverIcon from '../../assets/SVG/icons/Sidebar/dashboardhover.svg';
import DashboardActiveIcon from '../../assets/SVG/icons/Sidebar/dashboardactive.svg';

import DemandAnalysisIcon from '../../assets/SVG/icons/Sidebar/DemandAnalysis.svg';
import DemandAnalysisHoverIcon from '../../assets/SVG/icons/Sidebar/DemandAnalysishover.svg';
import DemandAnalysisActiveIcon from '../../assets/SVG/icons/Sidebar/DemandAnalysisActive.svg';

import AssortmentIcon from '../../assets/SVG/icons/Sidebar/Assortment.svg';
import AssortmentHoverIcon from '../../assets/SVG/icons/Sidebar/Assortmenthover.svg';
import AssortmentActiveIcon from '../../assets/SVG/icons/Sidebar/Assortmentactive.svg';

import ProductsIcon from '../../assets/SVG/icons/Sidebar/Products.svg';
import ProductsHoverIcon from '../../assets/SVG/icons/Sidebar/Productshover.svg';
import ProductsActiveIcon from '../../assets/SVG/icons/Sidebar/Productsactive.svg';

import InventoryInsightIcon from '../../assets/SVG/icons/Sidebar/InventoryInsight.svg';
import InventoryInsighHoverIcon from '../../assets/SVG/icons/Sidebar/InventoryInsighthover.svg';
import InventoryInsightActiveIcon from '../../assets/SVG/icons/Sidebar/InventoryInsightactive.svg';

import MyChartsIcon from '../../assets/SVG/icons/Sidebar/Mycharts.svg';
import MyChartsHoverIcon from '../../assets/SVG/icons/Sidebar/Mychartshover.svg';
import MyChartsActiveIcon from '../../assets/SVG/icons/Sidebar/Mychartsactive.svg';

import ProfileIcon from '../../assets/SVG/icons/Sidebar/Profile.svg';
import ProfileHoverIcon from '../../assets/SVG/icons/Sidebar/Profilehover.svg';
import ProfileActiveIcon from '../../assets/SVG/icons/Sidebar/Profileactive.svg';

import UserManagementIcon from '../../assets/SVG/icons/Sidebar/Usermanagement.svg';
import UserManagementHoverIcon from '../../assets/SVG/icons/Sidebar/Usermanagementhover.svg';
import UserManagementActiveIcon from '../../assets/SVG/icons/Sidebar/Usermanagementactive.svg';

import DataFilesIcon from '../../assets/SVG/icons/Sidebar/Datafiles.svg';
import DataFilesHoverIcon from '../../assets/SVG/icons/Sidebar/Datafileshover.svg';
import DataFilesActiveIcon from '../../assets/SVG/icons/Sidebar/Datafilesactive.svg';

import OnboardingIcon from '../../assets/SVG/icons/Sidebar/Onboarding.svg';
import OnboardingHoverIcon from '../../assets/SVG/icons/Sidebar/OnboardingHover.svg';
import OnboardingActiveIcon from '../../assets/SVG/icons/Sidebar/OnboardingActive.svg';

import ForecastSetupIcon from '../../assets/SVG/icons/Sidebar/Forecastsetup.svg';
import SignOutIcon from '../../assets/SVG/icons/Sidebar/Signout.svg';
import { logout } from '../../app/action';
import store from "../../app/store";

const Sidebar = ({ userType, designation }) => {
  const iconSize = 24;
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeOption, setActiveOption] = useState('');
  const [hoveredOption, setHoveredOption] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Fetch user profile from redux
  // const userProfile = useSelector((state) => state.user.user);

  // useEffect(() => {
  //   if (userProfile) {
  //     // Update userType and designation based on userProfile
  //     setUserType(userProfile.user_type || '');
  //     setDesignation(userProfile.designation || '');
  //   }
  // }, [userProfile]);

  useEffect(() => {
    const pathToOption = {
      '/dashboard': 'Dashboard',
      '/demand-analysis': 'Demand Analysis',
      '/assortment': 'Assortment',
      '/products': 'Products',
      '/inventory-insights': 'Inventory Insights',
      '/mycharts': 'My charts',
      '/profile': 'Profile',
      '/onboarding': 'Onboarding',
      '/usermanagement': 'User management',
      '/forecast-setup': 'Forecast setup',
      '/': 'Sign out'
    };

    const currentOption = pathToOption[location.pathname];
    if (currentOption) {
      setActiveOption(currentOption);
    }
  }, [location.pathname]);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getIcon = (name) => {
    switch (name) {
      case 'Dashboard':
        if (activeOption === name) return DashboardActiveIcon;
        if (hoveredOption === name) return DashboardHoverIcon;
        return DashboardIcon;
      case 'Demand Analysis':
        if (activeOption === name) return DemandAnalysisActiveIcon;
        if (hoveredOption === name) return DemandAnalysisHoverIcon;
        return DemandAnalysisIcon;
      case 'Assortment':
        if (activeOption === name) return AssortmentActiveIcon;
        if (hoveredOption === name) return AssortmentHoverIcon;
        return AssortmentIcon;
      case 'Products':
        if (activeOption === name) return ProductsActiveIcon;
        if (hoveredOption === name) return ProductsHoverIcon;
        return ProductsIcon;
      case 'Inventory Insights':
        if (activeOption === name) return InventoryInsightActiveIcon;
        if (hoveredOption === name) return InventoryInsighHoverIcon;
        return InventoryInsightIcon;
      case 'My charts':
        if (activeOption === name) return MyChartsActiveIcon;
        if (hoveredOption === name) return MyChartsHoverIcon;
        return MyChartsIcon;
      case 'Profile':
        if (activeOption === name) return ProfileActiveIcon;
        if (hoveredOption === name) return ProfileHoverIcon;
        return ProfileIcon;
      case 'User management':
        if (activeOption === name) return UserManagementActiveIcon;
        if (hoveredOption === name) return UserManagementHoverIcon;
        return UserManagementIcon;
      case 'Data & Files':
        if (activeOption === name) return DataFilesActiveIcon;
        if (hoveredOption === name) return DataFilesHoverIcon;
        return DataFilesIcon;
      case 'Onboarding':
        if (activeOption === name) return OnboardingActiveIcon;
        if (hoveredOption === name) return OnboardingHoverIcon;
        return OnboardingIcon;
      case 'Forecast setup':
        return ForecastSetupIcon;
      case 'Sign out':
        return SignOutIcon;
      default:
        return null;
    }
  };

  const handleOptionClick = (name) => {
    setActiveOption(name);
    localStorage.setItem('lastActiveOption', name);
    if (name === 'Forecast setup') {
      setIsModalVisible(true);
    }
    if(name == 'Sign out'){
      localStorage.setItem("Authorization","");
      dispatch(logout());
      
    }
  };

  const handleMouseEnter = (name) => {
    setHoveredOption(name);
  };

  const handleMouseLeave = () => {
    setHoveredOption('');
  };

  const getMenuItemStyle = (name) => {
    if (activeOption === name) {
      return {
        backgroundColor: ['Dashboard', 'Demand Analysis', 'Assortment', 'Products', 'Inventory Insights', 'My charts'].includes(name)
          ? '#7B61FF'
          : ['Profile', 'User management', 'Data & Files', 'Onboarding'].includes(name)
          ? '#288CD5'
          : '',
        color: '#FFFFFF',
        borderRadius: '10px'
      };
    } else if (hoveredOption === name) {
      return {
        backgroundColor: ['Dashboard', 'Demand Analysis', 'Assortment', 'Products', 'Inventory Insights', 'My charts', 'Forecast setup'].includes(name)
          ? '#EFECFF'
          : ['Profile', 'User management', 'Data & Files', 'Onboarding'].includes(name)
          ? '#E5F9FE'
          : name === 'Sign out'
          ? '#FFE7E7'
          : '',
        color: ['Dashboard', 'Demand Analysis', 'Assortment', 'Products', 'Inventory Insights', 'My charts', 'Forecast setup'].includes(name)
          ? '#7B61FF'
          : ['Profile', 'User management', 'Data & Files', 'Onboarding'].includes(name)
          ? '#288CD5'
          : name === 'Sign out'
          ? '#FF4444'
          : '#172935',
        borderRadius: '10px'
      };
    }
    return {
      color: ['Profile', 'User management', 'Onboarding', 'Data & Files'].includes(name)
        ? '#288CD5'
        : name === 'Forecast setup'
        ? '#7B61FF'
        : name === 'Sign out'
        ? '#FF4444'
        : '#172935'
    };
  };

  const getMenuItemsForUserType = (user_type) => {
    switch (user_type) {
      case 'root':
      case 'partner':
        return [
          { path: '/profile', name: 'Profile' },
          { path: '/onboarding', name: 'Onboarding' },
          { path: '/usermanagement', name: 'User management' },
          { path: '/', name: 'Sign out' }
        ];
      case 'end-customer':
        return [
          { path: '/dashboard', name: 'Dashboard' },
          { path: '/demand-analysis', name: 'Demand Analysis' },
          { path: '/assortment', name: 'Assortment' },
          { path: '/products', name: 'Products' },
          { path: '/inventory-insights', name: 'Inventory Insights' },
          { path: '/mycharts', name: 'My charts' },
          { path: '/profile', name: 'Profile' },
          // { path: '/onboarding', name: 'Onboarding' },
          { path: '/usermanagement', name: 'User management' },
          { path: '/forecast-setup', name: 'Forecast setup' },
          { path: '/', name: 'Sign out' }
        ];
      case 'customer':
        return [
          { path: '/dashboard', name: 'Dashboard' },
          { path: '/demand-analysis', name: 'Demand Analysis' },
          { path: '/assortment', name: 'Assortment' },
          { path: '/products', name: 'Products' },
          { path: '/inventory-insights', name: 'Inventory Insights' },
          { path: '/mycharts', name: 'My charts' },
          { path: '/profile', name: 'Profile' },
          // { path: '/onboarding', name: 'Onboarding' },
          { path: '/usermanagement', name: 'User management' },
          { path: '/forecast-setup', name: 'Forecast setup' },
          { path: '/', name: 'Sign out' }
        ];
      default:
        return [];
    }
  };

  const getFilteredMenuItems = () => {
    const menuItems = getMenuItemsForUserType(userType);
    return menuItems;

    switch (designation) {
      case 'Sales Personnel':
        return menuItems.filter(item => !['My charts', 'Onboarding', 'User management'].includes(item.name));
      case 'HOD sales':
        return menuItems.filter(item => !['Onboarding', 'User management'].includes(item.name));
      case 'Full Stack Developer':
        return menuItems.filter(item => !['Dashboard', 'Assortment', 'My charts', 'Onboarding', 'User management'].includes(item.name));
      default:
        return menuItems;
    }
  };

  return (
    <div className={`sidebar_main ${isSidebarOpen ? 'open' : 'closed'}`}>
      <div className='sidebar_container'>
        <div className='sidebar_top_content'>
          <div className="sidebar_toggle" onClick={handleSidebarToggle}>
            {isSidebarOpen ? <GoSidebarExpand size={iconSize} /> : <GoSidebarCollapse size={iconSize} />}
          </div>
          {getFilteredMenuItems()
            .filter(item => item.name !== 'Sign out' && item.name !== 'Forecast setup')
            .map((item, index) => (
              <React.Fragment key={index}>
                <NavLink
                  to={item.path}
                  key={index}
                  className={`sidebar_menu_item ${activeOption === item.name ? 'active' : ''}`}
                  onClick={() => handleOptionClick(item.name)}
                  onMouseEnter={() => handleMouseEnter(item.name)}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle(item.name)}
                >
                  <div className="sidebar_icon" style={getMenuItemStyle(item.name)}>
                    <img src={getIcon(item.name)} alt={item.name.toLowerCase()} />
                  </div>
                  {isSidebarOpen && <div className="sidebar_link_text">{item.name}</div>}
                </NavLink>
                {item.name === 'My charts' && <hr style={{ border: '1px solid #CFCFCF' }} />}
              </React.Fragment>
            ))}
        </div>
        <div className='sidebar_bottom_content'>
          {getFilteredMenuItems()
            .filter(item => item.name === 'Forecast setup' || item.name === 'Sign out')
            .map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className={`sidebar_menu_item ${activeOption === item.name ? 'active' : ''}`}
                onClick={() => handleOptionClick(item.name)}
                onMouseEnter={() => handleMouseEnter(item.name)}
                onMouseLeave={handleMouseLeave}
                style={getMenuItemStyle(item.name)}
              >
                <div className="sidebar_icon" style={getMenuItemStyle(item.name)}>
                  <img src={getIcon(item.name)} alt={item.name.toLowerCase()} />
                </div>
                {isSidebarOpen && <div className="sidebar_link_text">{item.name}</div>}
              </NavLink>
            ))}
        </div>
      </div>
      <ForecastingSetupModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        // onCreate={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default Sidebar;
