import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { hostname } from "../../Data"; // Ensure hostname is correctly defined in Data.js

const axiosInstance = axios.create({
  baseURL: hostname,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Define async thunks for fetching data without parameters
export const fetchSeasons = createAsyncThunk(
  'assortment/fetchSeasons',
  async () => {
    try {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const response = await fetch("https://forecast-be-coxlxzb2qa-el.a.run.app/forecasting/seasons/", requestOptions);
        if (!response.ok) {
            throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
  }
);

export const fetchGenders = createAsyncThunk(
  'assortment/fetchGenders',
  async () => {
    try {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const response = await fetch("https://forecast-be-coxlxzb2qa-el.a.run.app/forecasting/genders/", requestOptions);
        if (!response.ok) {
            throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
  }
);

export const fetchDivisions = createAsyncThunk(
  'assortment/fetchDivisions',
  async () => {
    try {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const response = await fetch("https://forecast-be-coxlxzb2qa-el.a.run.app/forecasting/divisions/", requestOptions);
        if (!response.ok) {
            throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
  }
);

export const fetchCategories = createAsyncThunk(
  'assortment/fetchCategories',
  async () => {
    try {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const response = await fetch("https://forecast-be-coxlxzb2qa-el.a.run.app/forecasting/categories-assortment/", requestOptions);
        if (!response.ok) {
            throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
  }
);

export const fetchBrands = createAsyncThunk(
  'assortment/fetchBrands',
  async () => {
   
    try {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const response = await fetch("https://forecast-be-coxlxzb2qa-el.a.run.app/forecasting/brands-assortment/", requestOptions);
        if (!response.ok) {
            throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
  }
);

// Create the slice
const assortmentModalSlice = createSlice({
  name: 'assortment',
  initialState: {seasons: [],
    genders: [],
    divisions: [],
    categories: [],
    brands: [],
    seasons: [],
    loading: false,
    error: null,
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      // Fetch Seasons
      .addCase(fetchSeasons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSeasons.fulfilled, (state, action) => {
        state.loading = false;
        state.seasons = action.payload;
        state.error = null;
      })
      .addCase(fetchSeasons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch Genders
      .addCase(fetchGenders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGenders.fulfilled, (state, action) => {
        state.loading = false;
        state.genders = action.payload;
        state.error = null;
      })
      .addCase(fetchGenders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch Divisions
      .addCase(fetchDivisions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDivisions.fulfilled, (state, action) => {
        state.loading = false;
        state.divisions = action.payload;
        state.error = null;
      })
      .addCase(fetchDivisions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch Categories
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
        state.error = null;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch Brands
      .addCase(fetchBrands.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload;
        state.error = null;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const {  } = assortmentModalSlice.actions;

export default assortmentModalSlice.reducer;
