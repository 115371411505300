import React from 'react'
import { Routes, Route } from 'react-router';
import LoginPage from './pages/UserOnboarding/LoginPage';
import ForgotPasswordPage from './pages/UserOnboarding/ForgotPassword';
import Navbar from './components/Navbar'
import Dashboard from './pages/Dashboard/Dashboard';
import Main from './Main';
function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path='/*' element={<Main />} />
      </Routes>
    </div>

  )
}

export default App