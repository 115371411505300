import React from 'react';
import Chart from 'react-apexcharts';

function BarChart({ row, col }) {
    const options = {
        chart: {
            type: 'bar',
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: col, // This will be your brand names
        },
        yaxis: {
            labels: {
                formatter: (value) => `$${value}`, // Formatting the label if needed
            }
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (value) => `$${value}`, // Formatting the tooltip if needed
            }
        }
    };

    // Ensure row (series) is in the correct format
    const series = [{
        data: row // This will be your total sales
    }];

    return (
        <div>
            <Chart options={options} series={series} type="bar" width="100%" />
        </div>
    );
}

export default BarChart;
