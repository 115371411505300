import React from 'react';
import { Modal, Button } from 'antd';
import '../css/DeleteConfirmationModal.css'; // Import the CSS file

const DeleteConfirmationModal = ({ visible, onOk, onCancel, message }) => {
  return (
    <Modal
      className='delete'
      title="Confirm Deletion"
      visible={visible}
      footer={null} // Disable default footer
      onCancel={onCancel} // Close modal on cancel
      centered // Ant Design prop to center the modal
    >
      <p>{message}</p>
      <div className="delete-modal-footer">
        <Button onClick={onCancel} className="cancel-button">
          Cancel
        </Button>
        <Button type="primary" danger onClick={onOk}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
