// src/features/Login/Modal/modalSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { hostname } from "../../../Data";
import { makeRequest } from "../../../api/api";

const axiosInstance = axios.create({
    baseURL: hostname, // Assuming hostname is defined correctly
    withCredentials: true, // Ensure credentials are included in requests
    headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
    }
});
const userType = localStorage.getItem('userType')

// Define async thunk for fetching customer data
// Adjust your async thunks to include console logs for debugging
// Define async thunk for fetching customer data
export const fetchCustomers = createAsyncThunk(
    'modal/fetchCustomers',
    async (param) => {
        try {
            // Fetch userType from localStorage if not provided (optional)
            const token = localStorage.getItem('Authorization');
            const cleanToken = token ? token.replace(/^Bearer\s*/, '').trim() : token;

            if (!cleanToken) {
                throw new Error('Token not found');
            }

            const response = await fetch(`${hostname}${userType}/get_customers_list/?user_type=${param}`, {
                method: 'GET',
                headers: {
                    Authorization: `${cleanToken}`,
                },
                redirect: 'follow',
            });
            console.log("response", response)
            if (!response.ok) {
                throw new Error('Failed to fetch customers');
            }

            const data = await response.json();
            console.log('Fetched customers:', data);
            return data;
        } catch (error) {
            console.error('Error fetching customers:', error);
            throw error;
        }
    }
);




// Define async thunk for fetching roles data
export const fetchRoles = createAsyncThunk(
    'modal/fetchRoles',
    async () => {
        try {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            const response = await fetch("https://forecast-be-coxlxzb2qa-el.a.run.app/user_profile/group-names/", requestOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch roles');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
);
// Define async thunk for fetching brand data
export const fetchBrands = createAsyncThunk(
    'modal/fetchBrands',
    async (_, { getState }) => {
        try {
            const selectedCountries = getState().modal.selectedCountry;
            const countryParams = selectedCountries ? selectedCountries.map(country => `country=${country}`).join('&') : '';
            const selectedGenders = getState().modal.selectedGender;
            const genderParams = selectedGenders ? selectedGenders.map(gender => `gender=${gender}`).join('&') : '';
            const selectedCategories = getState().modal.selectedCategory;
            const categoryParams = selectedCategories ? selectedCategories.map(category => `category=${category}`).join('&') : '';
            const response = await axios.get(`${hostname}forecasting/brands/?${countryParams}&${genderParams}&${categoryParams}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const fetchCategory = createAsyncThunk(
    'modal/fetchCategory',
    async (_, { getState }) => {
        try {
            const selectedCountries = getState().modal.selectedCountry;
            const countryParams = selectedCountries ? selectedCountries.map(country => `country=${country}`).join('&') : '';
            const selectedBrands = getState().modal.selectedBrand;
            const brandParams = selectedBrands ? selectedBrands.map(brand => `brand=${brand}`).join('&') : '';
            const selectedGenders = getState().modal.selectedGender;
            const genderParams = selectedGenders ? selectedGenders.map(gender => `gender=${gender}`).join('&') : '';
            const response = await axios.get(`${hostname}forecasting/category/?${countryParams}&${brandParams}&${genderParams}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const fetchGender = createAsyncThunk(
    'modal/fetchGender',
    async (_, { getState }) => {
        try {
            const selectedCountries = getState().modal.selectedCountry;
            const countryParams = selectedCountries ? selectedCountries.map(country => `country=${country}`).join('&') : '';
            const selectedBrands = getState().modal.selectedBrand;
            const brandParams = selectedBrands ? selectedBrands.map(brand => `brand=${brand}`).join('&') : '';
            const selectedCategories = getState().modal.selectedCategory;
            const categoryParams = selectedCategories ? selectedCategories.map(category => `category=${category}`).join('&') : '';

            const response = await axios.get(`${hostname}forecasting/gender/?${countryParams}&${brandParams}&${categoryParams}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

// Define async thunk for fetching country data
export const fetchCountries = createAsyncThunk(
    'modal/fetchCountries',
    async (_, { getState }) => {
        try {
            const selectedBrands = getState().modal.selectedBrand;
            const brandParams = selectedBrands ? selectedBrands.map(brand => `brand=${brand}`).join('&') : '';
            const selectedGenders = getState().modal.selectedGender;
            const genderParams = selectedGenders ? selectedGenders.map(gender => `gender=${gender}`).join('&') : '';
            const selectedCategories = getState().modal.selectedCategory;
            const categoryParams = selectedCategories ? selectedCategories.map(category => `category=${category}`).join('&') : '';
            const response = await axios.get(`${hostname}forecasting/countries/?${brandParams}&${genderParams}&${categoryParams}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

// Fetch Items (Products)
export const fetchItems = createAsyncThunk(
    'modal/fetchItems',
    async (params) => {
        try {
            const request = await makeRequest("get", `/customer/distinct-skus/`, { params });
            const response = request.data;

            // Assuming the API returns an array of items and we want to map to `item`
            return response.map((item) => item.item); // Replace `.item` with the correct field from the response
        } catch (error) {
            console.error("Error fetching items:", error);
            throw error;
        }
    }
);

// Fetch Locations
export const fetchLocations = createAsyncThunk(
    'modal/fetchLocations',
    async (params) => {
        try {
            const request = await makeRequest("get", `/customer/distinct-locations/`, { params });
            const response = request.data;

            // Assuming the API returns an array of locations and we want to map to `country`
            return response.map((location) => location.country); // Replace `.country` with the correct field from the response
        } catch (error) {
            console.error("Error fetching locations:", error);
            throw error;
        }
    }
);


const modalSlice = createSlice({
    name: "modal",
    initialState: {
        loading: false,
        brands: [],
        countries: [],
        genders: [],
        categories: [],
        selectedBrand: [],
        selectedCountry: [],
        selectedCategory: [],
        selectedGender: [],
        items: [],
        selectedItem: [],
        locations: [],
        selectedLocation: [],
        customers: [], // Add customers state
        roles: [], // Add roles state
        error: null,
    },
    reducers: {
        setSelectedBrand: (state, action) => {
            state.selectedBrand = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedGender: (state, action) => {
            state.selectedGender = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload;
        },
        clearSelections: (state) => {
            state.selectedBrand = [];
            state.selectedCountry = [];
            state.selectedItem = [];
            state.selectedLocation = [];
            state.selectedGender = [];
            state.selectedCategory = [];
        },
        clearCustomers: (state) => {
            state.customers = null; // Reset customers to null
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetching brands
            .addCase(fetchBrands.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBrands.fulfilled, (state, action) => {
                state.loading = false;
                state.brands = action.payload;
                state.error = null;
            })
            .addCase(fetchBrands.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Fetching countries
            .addCase(fetchCountries.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.loading = false;
                state.countries = action.payload;
                state.error = null;
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Fetching items
            .addCase(fetchItems.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
                state.error = null;
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Fetching locations
            .addCase(fetchLocations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLocations.fulfilled, (state, action) => {
                state.loading = false;
                state.locations = action.payload;
                state.error = null;
            })
            .addCase(fetchLocations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Fetching customers
            .addCase(fetchCustomers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.loading = false;
                state.customers = action.payload;
                state.error = null;
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.loading = false;
                state.customers = null;
                state.error = action.payload;
            })
            // Fetching roles
            .addCase(fetchRoles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.roles = action.payload;
                state.error = null;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // fetching categories
            .addCase(fetchCategory.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload;
                state.error = null;
            })
            .addCase(fetchCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(fetchGender.pending, (state, action) => {
                state.loading = true;
                state.error = null;

            })
            .addCase(fetchGender.fulfilled, (state, action) => {
                state.loading = false;
                state.genders = action.payload;
                state.error = null;
            })
            .addCase(fetchGender.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
});

export const { setSelectedBrand, setSelectedCountry, setSelectedGender, setSelectedCategory, clearSelections, setSelectedItem, setSelectedLocation, clearCustomers } = modalSlice.actions;

export default modalSlice.reducer;
