import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'antd';
import CardsHeading from '../../CommonComponents/CardsHeadingComponent';
import ForecastGraphFilters from './ForecastGraphFilters';
import Graph from './ForecastedMainGraph';
import '../../../css/DemandAnalysis/graphDemand.css';
import forecastedchartIcon from '../../../assets/SVG/icons/DemandAnalysis/forecastedcharticon.svg';
import { useDispatch, useSelector } from "react-redux";
function ForecastedChart() {

    const { forecastDemandAnalysisPlot } = useSelector((state) => state.demandAnalysis || {});
    const [loading, setLoading] = useState(true);
    const chartRef = useRef(null);
    const [highlightedDay, setHighlightedDay] = useState(null);
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    // const historicalData = [
    //     { "date": "2022-01-01", "sales": 15000 },
    //     { "date": "2022-02-01", "sales": 20000 },
    //     { "date": "2022-03-01", "sales": 18000 },
    //     { "date": "2022-04-01", "sales": 22000 },
    //     { "date": "2022-05-01", "sales": 25000 },
    //     { "date": "2022-06-01", "sales": 21000 },
    //     { "date": "2022-07-01", "sales": 27000 },
    //     { "date": "2022-08-01", "sales": 30000 },
    //     { "date": "2022-09-01", "sales": 25000 },
    //     { "date": "2022-10-01", "sales": 28000 },
    //     { "date": "2022-11-01", "sales": 30000 },
    //     { "date": "2022-12-01", "sales": 33000 },
    //     { "date": "2023-01-01", "sales": 35000 },
    //     { "date": "2023-02-01", "sales": 34000 },
    //     { "date": "2023-03-01", "sales": 32000 },
    //     { "date": "2023-04-01", "sales": 31000 },
    //     { "date": "2023-05-01", "sales": 36000 },
    //     { "date": "2023-06-01", "sales": 37000 },
    //     { "date": "2023-07-01", "sales": 38000 },
    //     { "date": "2023-08-01", "sales": 39000 },
    //     { "date": "2023-09-01", "sales": 37000 },
    //     { "date": "2023-10-01", "sales": 36000 },
    //     { "date": "2023-11-01", "sales": 35000 },
    //     { "date": "2023-12-01", "sales": 34000 },
    //     { "date": "2024-01-01", "sales": 32000 }
    // ];

    // const mlForecastedCatboosted = [
    //     { "date": "2024-01-01", "sales": 33000 },
    //     { "date": "2024-02-01", "sales": 34000 },
    //     { "date": "2024-03-01", "sales": 35000 },
    //     { "date": "2024-04-01", "sales": 36000 },
    //     { "date": "2024-05-01", "sales": 37000 },
    //     { "date": "2024-06-01", "sales": 38000 },
    //     { "date": "2024-07-01", "sales": 39000 },
    //     { "date": "2024-08-01", "sales": 40000 },
    //     { "date": "2024-09-01", "sales": 41000 },
    //     { "date": "2024-10-01", "sales": 42000 },
    //     { "date": "2024-11-01", "sales": 43000 },
    //     { "date": "2024-12-01", "sales": 44000 },
    //     { "date": "2025-01-01", "sales": 45000 }
    // ];

    // const mlForecasted = [
    //     { "date": "2024-01-01", "sales": 31000 },
    //     { "date": "2024-02-01", "sales": 32000 },
    //     { "date": "2024-03-01", "sales": 33000 },
    //     { "date": "2024-04-01", "sales": 34000 },
    //     { "date": "2024-05-01", "sales": 35000 },
    //     { "date": "2024-06-01", "sales": 36000 },
    //     { "date": "2024-07-01", "sales": 37000 },
    //     { "date": "2024-08-01", "sales": 38000 },
    //     { "date": "2024-09-01", "sales": 39000 },
    //     { "date": "2024-10-01", "sales": 40000 },
    //     { "date": "2024-11-01", "sales": 41000 },
    //     { "date": "2024-12-01", "sales": 42000 },
    //     { "date": "2025-01-01", "sales": 43000 }
    // ];


    const handleSpecialDayHover = (day) => {
        setHighlightedDay(day);
    };
    return (
        <Card
            className=' dashboard_card_containers'
            loading={loading}
            style={{ marginBottom: "20px" }}

        >
            <CardsHeading heading="Forecasted Chart" headingicon={forecastedchartIcon} dataTODownload={chartRef} showFilter={true} componentType="forecastedChart" />


            <div ref={chartRef} style={{ backgroundColor: 'white' }}>
                <Graph highlightedDay={highlightedDay} />
            </div>
            <ForecastGraphFilters handleSpecialDayHover={handleSpecialDayHover} />
        </Card>
    );
}

export default ForecastedChart;
