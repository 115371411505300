import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'antd';
import CardsHeading from '../CommonComponents/CardsHeadingComponent';
import SalesMonitorGraph from './SalesMonitorGraph';


function SalesMonitor() {

    const [loading, setLoading] = useState(true);
    const chartRef = useRef(null);
    useEffect(() => {
        // Simulate loading time
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Card
            className='dashboard_card_containers salesmonitor_main_container'
            loading={loading}
        >
            <CardsHeading heading="Sales Monitor" dataTODownload={chartRef} showFilter={true} componentType="salesmonitor" />
            <SalesMonitorGraph chartRef={chartRef} />
        </Card>
    )
}

export default SalesMonitor;

