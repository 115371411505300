import React, { useState } from 'react';
import { Modal, Upload, Button, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import UploadCloudIcon from '../assets/SVG/icons/Profile/Uploadicon.svg';
import UploadProfilePicIcon from '../assets/SVG/icons/Profile/uploadprofilepicicon.svg'
import "../css/Profile/ProfilePicModal.css";

const { Dragger } = Upload;

function ProfilePicModal({ visible, onClose, onSave }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return false;
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        message.error('Image must smaller than 1MB!');
        return false;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = e => setPreview(e.target.result);
      reader.readAsDataURL(file);
      return false; // Prevent automatic upload
    },
  };

  const handleSave = () => {
    if (selectedFile) {
      onSave(selectedFile);
      setSelectedFile(null);
      setPreview(null);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setPreview(null);
    onClose();
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px',color: '#7B61FF', marginBottom: '15px' }}>
            <img src={UploadProfilePicIcon} alt="icon" />
            Upload Profile Pic
        </div>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} style={{background: '#C6C6C6', marginTop: '15px', marginRight: '5px', color: '#172935' , opacity: '0.5'}}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} style={{background: '#7B61FF', marginTop: '15px'}}>
          Save
        </Button>,
      ]}
    >
      <div className="upload-profile-pic-modal">
        <Dragger {...uploadProps}>
          {preview ? (
            <img src={preview} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                {/* <InboxOutlined /> */}
                <img src={UploadCloudIcon} alt="upload-icon" />
              </p>
              <p className="ant-upload-text">Drag & drop files or <a style={{color: '#7B61FF'}}>Browse</a></p>
              <p className="ant-upload-hint">
                Supported format: jpeg, png. Kindly ensure the picture is below 1 MB.
              </p>
            </>
          )}
        </Dragger>
      </div>
    </Modal>
  );
}

export default ProfilePicModal;
