import React, { useState } from 'react';
import { Table, Pagination, Checkbox } from 'antd';
import { Card } from 'antd';
import forecastedataIcon from '../../../assets/SVG/icons/DemandAnalysis/forecastedataicon.svg';
import CardsFilter from '../../CommonComponents/CardsFilterComponent';
import CardsHeading from '../../CommonComponents/CardsHeadingComponent';
import { Search } from '@mui/icons-material';

const columns = [
    {
        title: '',
        dataIndex: 'color',
        key: 'color',
        onHeaderCell: () => ({
            style: { color: '#919191' }
        }),
        render: (text, record) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox style={{ marginRight: '20px' }} />
                <div style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: record.color,
                }}></div>
            </div>
        ),
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        onHeaderCell: () => ({
            style: { color: '#919191' }
        }),
    },
    {
        title: 'Item Code',
        dataIndex: 'itemcode',
        key: 'itemcode',
        onHeaderCell: () => ({
            style: { color: '#919191' }
        }),
    },
    {
        title: 'Rate of Sale',
        dataIndex: 'rateofsale',
        key: 'rateofsale',
        onHeaderCell: () => ({
            style: { color: '#919191' }
        }),
    },
    {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        onHeaderCell: () => ({
            style: { color: '#919191' }
        }),
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        onHeaderCell: () => ({
            style: { color: '#919191' }
        }),
    },
    {
        title: 'Season',
        dataIndex: 'season',
        key: 'season',
        onHeaderCell: () => ({
            style: { color: '#919191' }
        }),
    },
];

function AssortmentPlanningData({ data }) {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const currentPageData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(item => ({
        key: item.id,
        location: item.location,
        itemcode: item.item,
        rateofsale: item.rate_of_sale,
        brand: item.brand,
        category: item.category,
        season: item.season_code,
    }));

    return (
        <Card className='dashboard_card_containers'>
            <div className='forecasted_data_main'>
                <CardsHeading heading="Assortment Planning - Data" headingicon={forecastedataIcon} showFilter={false} dataTODownload={data} componentType="assortPlanningData" />
                <div style={{ marginTop: "8px" }} className='best-selling-brands forecast-graph-filters-container'>
                    <CardsFilter isAssortment={true} />
                </div>
                <div className='assort_plan_search_sort'>
                    <div className="assort_plan_search_bar">
                        <input className="assort_plan_search_input" type="text" placeholder="Search here..." />
                        <Search className="assort_plan_search_icon" />
                    </div>
                </div>
                <div>
                    <Table
                        className='assortment-planning-table'
                        columns={columns}
                        dataSource={currentPageData}
                        pagination={false}
                        rowKey="key"
                        rowClassName={() => 'hover-row'}
                    />
                    <Pagination
                        current={currentPage}
                        total={data.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        style={{ marginTop: '16px', textAlign: 'left' }}
                    />
                </div>
            </div>
        </Card>
    );
}

export default AssortmentPlanningData;
