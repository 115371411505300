import { Table } from "antd";

function OnboardingTable({ rowClassName, rowSelection, columns, pagination, dataSource, onChange }) {
  return (
    <Table
      rowClassName={rowClassName}
      rowSelection={rowSelection}
      columns={columns}
      pagination={pagination}
      onChange={onChange} // Directly use the onChange prop
      dataSource={dataSource}
      rowKey="id"
    />
  );
}

export default OnboardingTable;
