import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";



export const forecastSetupApi = createAsyncThunk(
    'forecastSetup/getForecastSetupData',
    async (params, thunkAPI) => {
        // console.log(params)
        const endpoint = `customer/forecast_setups/`
        const request = await makeRequest("get", endpoint);
        const response = await request.data;
        return response;
    }
);
export const postForecastSetupApi = createAsyncThunk(
    'forecastSetup/postForecastSetupData',
    async (params, thunkAPI) => {
        console.log(params)
        const endpoint = `/customer/create_forecast_setup/?baseline_model=${params.selectBaselinemodel}&rollback_model=${params.selectfallbackmodel}&forecast_period=${params.forecastperiod}&validation_period=${params.validationperiod}&error_metric=${params.errormetric}&cross_validation=${params.crossvalidations}&forecast_time_level=${params.forecasttimelevel}&forecast_model=${params.selectForecastmodel[0]}`
        const request = await makeRequest("post", endpoint);
        const response = await request.data;
        return response;
    }
);
const forecastSetupSlice = createSlice({
    name: "forecast_setup",
    initialState: {
        status: 'idle',
        forecastSetupData: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(forecastSetupApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(forecastSetupApi.fulfilled, (state, action) => {
                // console.log('Thunk fulfilled action:', action); // Log the action
                state.status = 'succeeded';
                state.forecastSetupData = action.payload;
            })
            .addCase(forecastSetupApi.rejected, (state) => {
                state.status = 'failed';
            })
    },
});


export default forecastSetupSlice.reducer;