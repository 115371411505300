import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";
import { drop } from "lodash";

export const levelDataApi = createAsyncThunk(
    'filter/levelData',
    async (params, thunkAPI) => {
        const request = await makeRequest("get", `customer/levels/`);
        const response = await request.data;
        return response;
    }
);

export const drodownDataApi = createAsyncThunk(
    'filter/drodownData',
    async (params, thunkAPI) => {
        const queryString = Object.keys(params)
            .map(key =>
                params[key].map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&')
            )
            .join('&');

        console.log(queryString);
        const request = await makeRequest("get", `customer/filtered_product_data/?${queryString}`);
        const response = await request.data;
        return response;
    }
);

export const getCountries = createAsyncThunk(
    'filter/getCountries',
    async (params, thunkAPI) => {
        const request = await makeRequest("get", `customer/get_countries/`);
        const response = await request.data;
        return response;
    }
);

export const getSeasons = createAsyncThunk(
    'filter/getSeasons',
    async (params, thunkAPI) => {
        const request = await makeRequest("get", `customer/seasons/`);
        const response = await request.data;
        return response;
    }
);

const FilterSlice = createSlice({
    name: "filter",
    initialState: {
        status: 'idle',
        levelData: [],
        dropdownData: {},
        countriesData: [],
        seasonData: [],
        globalFilters: {},
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(levelDataApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(levelDataApi.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.levelData = action.payload.data;
                // console.log(action)
            })
            .addCase(levelDataApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(drodownDataApi.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(drodownDataApi.fulfilled, (state, action) => {
                state.status = 'fulfilled';

                // Get selected filters from the API params (in action.meta.arg)
                const selectedFilters = action.meta.arg;
                state.globalFilters = action.meta.arg

                // Store the new data from the API response
                const newDropdownData = action.payload.data;

                // Iterate over the newDropdownData and keep the previously selected filters unchanged
                const updatedDropdownData = { ...state.dropdownData };

                Object.keys(newDropdownData).forEach(key => {
                    // If the key is present in the selected filters (meaning it's filtered), keep the previous state
                    if (selectedFilters[key]) {
                        updatedDropdownData[key] = state.dropdownData[key];
                    } else {
                        // Otherwise, update the dropdown data with the new data from the API response
                        updatedDropdownData[key] = newDropdownData[key];
                    }
                });

                // Update the dropdownData state with the merged data
                state.dropdownData = updatedDropdownData;
                console.log(updatedDropdownData)
            })
            .addCase(drodownDataApi.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getCountries.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.countriesData = action.payload.countries;
                // console.log(action.payload.countries)
            })
            .addCase(getCountries.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getSeasons.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSeasons.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.seasonData = action.payload.data;
                console.log(action.payload.data)
            })
            .addCase(getSeasons.rejected, (state) => {
                state.status = 'failed';
            })
    },
});

export const { } = FilterSlice.actions;

export default FilterSlice.reducer;
