import React from 'react'
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css";
import UserManagementArea from '../../components/UserManagementComponents/UserManagementArea';
// import EmptyState from '../../components/CommonComponents/EmptyStateComponent';
// import NoDataImg from "../../assets/Images/NoData.png"

function UserManagementPage() {
  return (
    <div style={{height: "95%", margin: "10px", padding: "10px"}}>
        <UserManagementArea />
    </div>
  )
}

export default UserManagementPage