// import React from 'react';

// import Chart from 'react-apexcharts';
// import { useSelector } from 'react-redux';

// function AssortmentPlot() {
//     const { status, assortmentPlot } = useSelector((state) => state.assortment);

//     const series = [
//         {
//             name: 'Rate of Sale',
//             data: assortmentPlot.map(item => ({ x: item.location, y: item.rate_of_sale }))
//         },
//         {
//             name: 'Red Dots',
//             data: assortmentPlot.filter(item => item.color === 'red').map(item => ({ x: item.location, y: item.rate_of_sale }))
//         },
//         {
//             name: 'Green Dots',
//             data: assortmentPlot.filter(item => item.color === 'green').map(item => ({ x: item.location, y: item.rate_of_sale }))
//         }
//     ];
// =======
import { useSelector } from 'react-redux';
import { Scatter } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

function AssortmentPlot() {
    const { assortmentPlotMainData } = useSelector((state) => state.assortment || {});

    const xValues = assortmentPlotMainData.flatMap(item => item.data.map(point => point.x));
    const yValues = assortmentPlotMainData.flatMap(item => item.data.map(point => point.y));

    const xMin = Math.min(...xValues) * 0.9; // Decrease min by 10%
    const xMax = Math.max(...xValues) * 1.1;
    const yCategories = Array.from(new Set(yValues)); 


    console.log("assortmentPlotMainData",assortmentPlotMainData)
    const data = {
        datasets: assortmentPlotMainData.map((item) => ({
            label: item.name,
            backgroundColor: item.color,
            data: item.data.map((point) => ({
                x: point.x,
                y: point.y,
            })),
        })),
    };




    const options = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Rate of Sales',
                },
                min: xMin,
                max: xMax,
            },
            y: {
                type: 'category',
                categories: yCategories,
                title: {
                    display: true,
                    text: 'Location Code',
                },
            },
        },
    };

    return (
        <Scatter data={data} options={options} />
    );
}

export default AssortmentPlot;
