import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Objects from '../../assets/Images/Objects.png';
import EmptyState from '../../components/CommonComponents/EmptyStateComponent';
import PieChart from '../../components/MyChartsComponents/PieChart'; // Assuming PieChart component is imported
import LineChart from '../../components/MyChartsComponents/LineChart'; // Assuming LineChart component is imported
import BarChart from '../../components/MyChartsComponents/BarChart'; // Assuming BarChart component is imported
import { fetchMyChartData } from '../../features/MyCharts/MyChartsSlice';

function MyChartPage() {
    const dispatch = useDispatch();
    const { myChartData = [], status } = useSelector((state) => state.myCharts || {});

    const [selectedChart, setSelectedChart] = useState(null);
    const [chartData, setChartData] = useState({ row: [], col: [] });

    useEffect(() => {
        if (selectedChart && selectedChart.SelectDataSource && selectedChart["select chart type"]) {
            const params = {
                dataset: selectedChart.SelectDataSource,
                chart_type: selectedChart["select chart type"],
            };
            dispatch(fetchMyChartData(params));
        }
    }, [selectedChart, dispatch]);

    const handleCreateChart = (chartData) => {
        setSelectedChart(chartData);
    };

    const extractDataForChart = (inputArr) => {
        if (!Array.isArray(inputArr)) return;

        const rows = [];
        const cols = [];
        const monthsToShow = ['Jan', 'May', 'Sep']; // Show data for January, May, and September
        const monthIndices = [0, 4, 8]; // Indices of January, May, and September

        // Create an object to hold aggregated data
        const aggregatedData = {};

        inputArr.forEach(element => {
            if (selectedChart["select chart type"] === 'line chart') {
                const date = new Date(element.date);
                const month = date.getMonth();
                const year = date.getFullYear();
                const monthName = date.toLocaleString('default', { month: 'short' });

                if (monthIndices.includes(month)) {
                    const key = `${monthName} ${year}`;
                    if (!aggregatedData[key]) {
                        aggregatedData[key] = 0;
                    }
                    aggregatedData[key] += element.total_sales;
                }
            } else if (selectedChart["select chart type"] === 'bar chart') {
                rows.push(element.brand);
                cols.push(element.total_sales);
            } else if (selectedChart["select chart type"] === 'pie chart') {
                rows.push(element.category);
                cols.push(element.sales_percentage);
            }
        });

        // Populate the rows and cols arrays with aggregated data
        for (const [key, value] of Object.entries(aggregatedData)) {
            rows.push(key);
            cols.push(value);
        }

        setChartData({ row: rows, col: cols });
    };

    useEffect(() => {
        if (myChartData && myChartData.length > 0 && selectedChart) {
            extractDataForChart(myChartData);
        }
    }, [myChartData, selectedChart]);

    return (
        <div className='dashboard_main_container'>
            {status === 'loading' ? (
                <div>Loading...</div>
            ) : selectedChart !== null && selectedChart["select chart type"] ? (
                selectedChart["select chart type"] === 'pie chart' ? (
                    <PieChart row={chartData.row} col={chartData.col} />
                ) : selectedChart["select chart type"] === 'line chart' ? (
                    <LineChart row={chartData.row} col={chartData.col} />
                ) : selectedChart["select chart type"] === 'bar chart' ? (
                    <BarChart row={chartData.row} col={chartData.col} />
                ) : null
            ) : (
                <EmptyState 
                img={Objects} 
                data={(
                  <>
                    Please create a new chart from the data 
                    <br />
                    available to view here.
                  </>
                )} 
                page="MyChart" 
                onCreateChart={handleCreateChart} 
              />
              
        )}
        </div>
    );
}

export default MyChartPage;
