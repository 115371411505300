import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, notification, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, fetchRoles, clearCustomers } from '../features/Login/Modal/modalSlice';
import { createUser, fetchUsers } from '../features/UseMangementSlice';

const { Option } = Select;

const AddUser = ({ visible, onOk, onCancel }) => {
  const dispatch = useDispatch();
  const { customers, loading } = useSelector((state) => state.modal);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const userType = localStorage.getItem('userType');

  const [choosenUserType, setChoosenUserType] = useState(null);
  const [roles, setRoles] = useState(["Admin", "Creator", "Employee", "Forecaster", "Viewer"]);

  const [userCategory, setUserCategory] = useState(() => {
    switch (userType) {
      case 'root':
        return ["Valiance", "Partner", "End Customer"];
      case 'partner':
        return ["Partner", "End Customer"];
      case 'customer':
        return ["End Customer"];
      default:
        return [];
    }
  });

  const capitalizeWords = str => str ? str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase()) : '';

  useEffect(() => {
    if (visible) {
      dispatch(fetchCustomers());
      dispatch(fetchRoles());
    }
  }, [visible, dispatch]);

  useEffect(() => {
    if(customers){
      console.log("choosenUserType",choosenUserType,userType)
      if (choosenUserType == userType && customers.length > 0) {
        console.log("{ key: customers[0].name,value: customers[0].name}",{ key: customers[0].name,value: customers[0].name})
        form.setFieldsValue({ customer: customers[0].database_name });
      } 
      else if (choosenUserType == "valiance" && userType == "root" && customers.length > 0) {
        console.log("{ key: customers[0].name,value: customers[0].name}",{ key: customers[0].name,value: customers[0].name})
        form.setFieldsValue({ customer: customers[0].name });
      } 
      else{
        form.setFieldsValue({ customer: null });
      }
    }
    else{
      form.setFieldsValue({ customer: null });
    }
    
  }, [choosenUserType,customers,userType]);

 

  const handleSelectUserCategory = async (value) => {
    dispatch(clearCustomers());
    await dispatch(fetchCustomers(value));
    setChoosenUserType(value);

  };

  useEffect(() => {
    if (customers && choosenUserType && customers.length === 0 && userType !== choosenUserType) {
      message.error(`Onboard a ${choosenUserType} first`, 5);
    }
  }, [customers, choosenUserType, userType]);

  const isInvalidSelection = (userType, choosenUserType) => {
    return userType !== choosenUserType && !(userType === 'root' && choosenUserType === 'valiance');
  };

  const onFinish = async (values) => {
    if (submitting) return;

    try {
      setSubmitting(true);
      const userDetails = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        designation: values.designation,
        database_name: values.database,
        contact_number: values.contactNumber,
        user_type: values.user_type,
        customer: values.customer,
        role: values.role,
      };

      const createUserAction = dispatch(createUser(userDetails));
      const response = await createUserAction;

      if (response.error) {
        throw new Error(response.error.message);
      }

      notification.success({
        message: 'User Added Successfully',
        description: `User ${values.first_name} ${values.last_name} has been added.`,
      });

      await dispatch(fetchUsers());
      form.resetFields();
      setSubmitting(false);
      await onOk();
    } catch (error) {
      notification.error({
        message: 'Error Adding User',
        description: `There was an error adding the user: ${error.message}`,
      });
      setSubmitting(false);
    }
  };

  return (
    
      <Modal
        title="Add User"
        visible={visible}
        onCancel={onCancel}
        okText="Submit"
        confirmLoading={submitting}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout="vertical"
          name="add_user_form"
          className='add-user-modal-content'
          onFinish={onFinish}
        >
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[{ required: true, message: 'Please input your first name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Designation"
            name="designation"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Database"
            name="database"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contact Number"
            name="contactNumber"
            rules={[{ required: true, message: 'Please input your contact number!' }]}
          >
            <Input />
          </Form.Item>

          {userType !== "customer" && (
            <>
              <Form.Item
                label="User Category"
                name="user_type"
                rules={[{ required: true, message: 'Please select a user category!' }]}
              >
                <Select
                  placeholder="Select a user category"
                  loading={loading}
                  onChange={handleSelectUserCategory}
                  allowClear
                >
                  {userCategory.map((category) => {
                    const value = category === "End Customer" ? "end-customer" : category.toLowerCase();
                    return (
                      <Option key={category} value={value}>
                        {category}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Customer"
                name="customer"
                rules={[{
                  required: isInvalidSelection(userType, choosenUserType),
                  message: 'Please select a customer!',
                }]}
              >
                <Select
                  placeholder="Select a customer"
                  loading={loading}
                  disabled={!isInvalidSelection(userType, choosenUserType)}
                  allowClear
                >
                  {customers?.length > 0 ? (
                    customers.map((customer) => (
                      <Select.Option key={customer.name} value={customer.name}>
                        {customer.name}
                      </Select.Option>
                    ))
                  ) : null}
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: 'Please select a role!' }]}
          >
            <Select
              placeholder="Select or create a role"
              loading={loading}
              allowClear
              optionFilterProp="children"
            >
              {roles.map((role) => (
                <Option key={role} value={role}>
                  {capitalizeWords(role)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    
  );
};

export default AddUser;
