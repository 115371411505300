import React, { useState } from 'react';
import { Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { toPng } from 'html-to-image';

import { overallAnalysisApi, categoryAnalysisApi, salesMonitorApi, topSellingProductsApi, salesAnalysisGrowthApi } from "../../features/Dashboard/DashboardSlice";
import { demandAnalysisData, demandAnalysisPlot } from "../../features/DemandAnalysis/DemandAnalysisSlice";
import { fetchChartData } from '../../features/Product/ProductSlice';
import { assortmentTableDataApi, assortmentPlotApi } from "../../features/Assortment/AssortmentSlice";
import ForecastingSetupModal from '../ForecastSetupComponents/ForecastSetupModal/ForecastingSetupModal';

const { Option } = Select;

const CardsHeading = ({ heading, headingicon, dataTODownload, showFilter, componentType }) => {
    const [filters, setFilter] = useState('Monthly');
    const currentPath = window.location.pathname;
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const defaultFilterItems = [
        { filterName: 'Yearly', filterValue: 'Yearly' },
        { filterName: 'Monthly', filterValue: 'Monthly' },
        { filterName: 'Weekly', filterValue: 'Weekly' },
        { filterName: 'Daily', filterValue: 'Daily' }
    ];

    const dashboardFilterItems = [
        { filterName: 'Current Year', filterValue: 'Yearly' },
        { filterName: 'Current Month', filterValue: 'Monthly' },
        { filterName: 'Current Week', filterValue: 'Weekly' },
        { filterName: 'Current Day', filterValue: 'Daily' }
    ];

    const filterItems = currentPath === '/dashboard' ? dashboardFilterItems : defaultFilterItems;
    const filteredItems = componentType === 'salesmonitor' ? filterItems.filter(item => item.filterName !== 'Current Day' && item.filterName !== 'Daily') : filterItems;

    const { levelFilter } = useSelector((state) => state.demandAnalysis || "Set Filter");

    const handleFilterChange = (value) => {
        setFilter(value);

        const filter = { filterType: value, payload: value };

        switch (currentPath) {
            case '/demand-analysis':
                dispatch(demandAnalysisData({ filter, filterName: "level" }));
                dispatch(demandAnalysisPlot({ filter, filterName: "level" }));
                break;
            case '/dashboard':
                switch (componentType) {
                    case 'categoryanalysis':
                        dispatch(categoryAnalysisApi(value));
                        break;
                    case 'salesanalysis':
                        dispatch(overallAnalysisApi(value));
                        dispatch(salesAnalysisGrowthApi(value));
                        break;
                    case 'salesmonitor':
                        dispatch(salesMonitorApi(value));
                        break;
                    case 'topSellingProducts':
                        dispatch(topSellingProductsApi(value));
                        break;
                    default:
                        console.log("Component not found");
                        break;
                }
                break;
            case '/products':
                dispatch(fetchChartData({ filter, filterName: "level" }));
                break;
            case '/assortment':
                dispatch(assortmentPlotApi({ filter, filterName: "level" }));
                dispatch(assortmentTableDataApi({ filter, filterName: "level" }));
                break;
            default:
                console.log("No Path");
                break;
        }
    };

    const handleDownload = () => {
        if (dataTODownload.current) {
            const originalBackgroundColor = dataTODownload.current.style.backgroundColor;
            dataTODownload.current.style.backgroundColor = '#ffffff';

            toPng(dataTODownload.current)
                .then((dataUrl) => {
                    dataTODownload.current.style.backgroundColor = originalBackgroundColor;

                    const link = document.createElement('a');
                    link.download = 'data_visuals.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((error) => {
                    dataTODownload.current.style.backgroundColor = originalBackgroundColor;
                    console.error('Error capturing chart as image:', error);
                });
        } else {
            const dataArray = dataTODownload.map(obj => Object.values(obj));
            const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Forecast Data");
            XLSX.writeFile(workbook, "forecast_data.xlsx");
        }
    };

    return (
        <div className='cards_heading'>
            <div className='card_heading_left_elem'>
                {headingicon !== undefined && <img src={headingicon} alt="heading-icon" />}
                <h5>{heading}</h5>
            </div>
            <div className='card_heading_right_elem'>
                {componentType === 'forecastSetupTable' && (
                    <div className='card_heading_download_btn' onClick={() => setIsModalVisible(true)}>
                        <span>Add Forecast Configuration</span>
                    </div>
                )}
                <div className='card_heading_download_btn' onClick={handleDownload}>
                    <span><DownloadOutlined /> Download</span>
                </div>
                {showFilter && (
                    <Select
                        defaultValue={filters}
                        className='cards_heading_select'
                        onChange={handleFilterChange}
                    >
                        {filteredItems.map(option => (
                            <Option key={option.filterValue} value={option.filterValue}>{option.filterName}</Option>
                        ))}
                    </Select>
                )}
            </div>
            <ForecastingSetupModal
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                setIsModalVisible={setIsModalVisible}
            />
        </div>
    );
}

export default CardsHeading;
