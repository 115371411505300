import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from "react-redux";
import FullscreenModal from "../../components/CommonComponents/FullscreenModal";

function SalesMonitorGraph({ chartRef }) {
    const { salesMonitorData, salesMOnitorFilter } = useSelector((state) => state.dashboard || {});

    console.log(salesMOnitorFilter)

    // Check if salesMonitorData is an object and transform it accordingly
    let filteredData = salesMonitorData;
    if (typeof filteredData === 'object' && !Array.isArray(filteredData)) {
        filteredData = Object.values(filteredData);
    }

    if (salesMOnitorFilter !== "Yearly" && salesMOnitorFilter !== "Weekly" && salesMOnitorFilter !== "Daily") {
        filteredData = filteredData.filter(item => item !== null);
    }

    // Transform the data and filter out null values
    const dataPoints = filteredData.map((item, index) => ({
        x: index + 1,
        y: item ? parseFloat(item.replace('%', '')) : null
    })).filter(point => point.y !== null);

    const series = [{
        name: 'Sales rate',
        data: dataPoints.map(point => point.y)
    }];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const options = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: true, // Enable zooming
                type: 'x', // Enable only horizontal zooming
                autoScaleYaxis: true // Auto scale Y-axis on zoom
            },
            toolbar: {
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: [{
                        icon: '<span>&#x26F6;</span>', // Fullscreen icon
                        index: 4,
                        title: 'Fullscreen',
                        class: 'custom-fullscreen',
                        click: function (chart, options, e) {
                            showModal();
                        }
                    }]
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 1.17,  // Border width
            colors: ['#7B61FF']  // Stroke color
        },
        xaxis: {
            categories: dataPoints.map(point => point.x),
            title: {
                text: salesMOnitorFilter === "Yearly" ? 'months' : salesMOnitorFilter === "Weekly" ? 'days' : 'weeks',
                style: {
                    color: '#009EFD',
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 500
                }
            },
            tickAmount: Math.min(dataPoints.length, 10) // Display up to 10 labels on the x-axis
        },
        yaxis: {
            title: {
                text: 'Sales',
                style: {
                    color: '#009EFD',
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 500
                }
            },
            labels: {
                formatter: (val) => `${val}%`
            }
        },
        tooltip: {
            enabled: true,
            theme: 'light',
            x: {
                show: false
            },
            y: {
                formatter: (val) => `${val}%`
            },
            marker: {
                show: true
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
                colorStops: [
                    {
                        offset: 0,
                        color: "#C8BDFF",
                        opacity: 1
                    },
                    {
                        offset: 100,
                        color: "rgba(123, 97, 255, 0)",
                        opacity: 1
                    }
                ]
            }
        },
        markers: {
            size: 0  // Set marker size to 0 to remove circle pointers
        },
        grid: {
            borderColor: '#e7e7e7',
        }
    };

    return (
        <div ref={chartRef} className='sales-monitor-graph'>
            <Chart options={options} series={series} type='area' height={350} />
            <FullscreenModal visible={isModalVisible} onClose={handleClose} title="Forecasted Graph" options={options} series={series} type="line" height={350} />
        </div>
    );
}

export default SalesMonitorGraph;
