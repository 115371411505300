import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'antd';
import ReactApexChart from 'react-apexcharts';
import CardsHeading from '../CommonComponents/CardsHeadingComponent';
import { useSelector } from "react-redux";

function CategoryAnalysis() {

    const [loading, setLoading] = useState(true);
    const { categoryAnalysisData } = useSelector((state) => state.dashboard || []);
    const chartRef = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    // const CategoryAnalysisData = [
    //     { color: '#7B61FF', percent: '53%', name: 'Footwear', brand: "Nike" },
    //     { color: '#00C1F9', percent: '15%', name: 'Top wear', brand: "Adidas" },
    //     { color: '#2AC239', percent: '5%', name: 'Bottom wear', brand: "Converse" },
    //     { color: '#00C1F9', percent: '25%', name: 'Accessories', brand: "Adidas" },
    //     { color: '#CFCFCF', percent: '20%', name: 'Others', brand: "Adidas" },
    // ];


    const ColorsPallate = ['#7B61FF', '#009EFD', '#00C6AE', '#FF4D4F']

    // Function to generate a random color
    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };


    // Function to assign colors dynamically
    const getSalesAnalysisData = (data) => {
        return data.map((item, index) => {
            const color = item.category === "Others"
                ? '#A9A9A9'
                : (index < ColorsPallate.length
                    ? ColorsPallate[index % ColorsPallate.length]
                    : generateRandomColor());
            return {
                color: color,
                percent: item.contribution,
                top_brand: item.top_brand,
                category: item.category
            };
        });
    };
    const categoryAnalysisMainData = getSalesAnalysisData(categoryAnalysisData);

    const chartSeries = categoryAnalysisMainData.map(item => parseInt(item.percent));
    // Find the category with the maximum percentage
    const maxCategory = categoryAnalysisMainData.reduce((max, item) => {
        return parseInt(item.percent) > parseInt(max.percent) ? item : max;
    }, categoryAnalysisMainData[0]);

    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: categoryAnalysisMainData.map(item => item.category),
        colors: categoryAnalysisMainData.map(item => item.color),
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'max',
                            formatter: () => maxCategory.category
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val, opts) => opts.w.config.series[opts.seriesIndex] + '%',
        },
        tooltip: {
            y: {
                formatter: (val) => `${val}%`
            }
        },
        legend: {
            show: false, // Hides the legend
        },
    };



    return (
        <Card
            className='dashboard_card_containers categoryanalysis_main_container'
            loading={loading}
        >
            <CardsHeading heading="Category Analysis" dataTODownload={chartRef} showFilter={true} componentType="categoryanalysis" />
            {!loading && (
                <div ref={chartRef} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}>
                    <div style={{ width: '40%' }}>
                        <ul style={{ listStyleType: 'none', padding: 0, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', width: "100%" }}>
                            {categoryAnalysisMainData.map((item, index) => (
                                <li key={index} style={{ margin: '5px 0' }}>
                                    <span style={{ color: item.color, fontWeight: 'bold' }}>{item.category}</span>
                                    <p style={{ color: "#172935", fontWeight: 'bold', fontSize: "14px" }}>{item.top_brand}</p>

                                </li>
                            ))}
                        </ul>
                    </div>
                    <ReactApexChart

                        options={chartOptions}
                        series={chartSeries}
                        type="donut"
                        width="380"
                    />
                </div>
            )}
        </Card>
    );
}

export default CategoryAnalysis;
