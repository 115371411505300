import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox, Input, Badge } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { fetchBrands, fetchCountries, fetchGender, fetchCategory, setSelectedBrand, setSelectedCountry, setSelectedCategory, setSelectedGender, clearSelections, fetchItems, fetchLocations } from '../features/Login/Modal/modalSlice';
import { demandAnalysisData, demandAnalysisPlot } from "../features/DemandAnalysis/DemandAnalysisSlice";
import { fetchSimilarItems, fetchChartData } from '../features/Product/ProductSlice';
import { setSelectedItem, setSelectedLocation } from '../features/Product/ProductSlice';
import { drodownDataApi } from '../features/Filters/FiltersSlice';

const BrandModal = ({ visible, closeModal, buttonType }) => {
  const dispatch = useDispatch();
  const { brands, countries, genders, categories, loading, selectedBrand, selectedCountry, selectedCategory, selectedGender, items, locations } = useSelector((state) => state.modal);
  const { dropdownData, globalFilters, countriesData, seasonData } = useSelector((state) => state.filter);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const currentPath = window.location.pathname;

  useEffect(() => {
    if (visible) {
      const savedInputFields = localStorage.getItem("inputFields");
      if (savedInputFields) {
        const inputFields = JSON.parse(savedInputFields);
        const field = inputFields.find((field) => field.value === buttonType);
        if (field && field.selectAs) {
          setSelectedItems(field.selectAs.split(', '));
        }
      }
    }
  }, [visible, buttonType]);

  useEffect(() => {
    if (buttonType === 'Items') {
      dispatch(fetchItems());
    } else if (buttonType === 'Location') {
      dispatch(fetchLocations());
    }
  }, [buttonType, dispatch]);

  const itemData = buttonType === "Location" ? locations.map((location) => location) :
  buttonType === "Items" ? items.map((item) => item) : dropdownData[buttonType.toLowerCase()];

  const filteredItems = itemData?.filter(item => typeof item === 'string' && item.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleApply = () => {
    const payload = {
      ...globalFilters,
      [buttonType.toLowerCase()]: selectedItems.length > 0 ? selectedItems : [],
    }
    dispatch(drodownDataApi(payload)).then(() => {
      switch (currentPath) {
        case '/demand-analysis':
          dispatch(demandAnalysisData({ selectedItems, filterName: buttonType.toLowerCase() }));
          dispatch(demandAnalysisPlot({ selectedItems, filterName: buttonType.toLowerCase() }));
          break;
        case '/products':
          dispatch(fetchSimilarItems({ selectedItems, filterName: buttonType.toLowerCase() }));
          dispatch(fetchChartData({ selectedItems, filterName: buttonType.toLowerCase() }));
          dispatch(setSelectedItem(selectedItems));
          break;
        default:
          console.log("No Path");
          break;
      }
      setSelectedItems([]);
      closeModal();
    });
  };

  const handleClose = () => {
    setSelectedItems([]);
    closeModal();
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedItems(checked ? filteredItems.map((item) => item) : []);
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((i) => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSelection = () => {
    setSelectedItems([]);
    setSelectAll(false);
    dispatch(clearSelections());
    dispatch(fetchBrands());
    dispatch(fetchCountries());
  };

  const isChecked = (item) => selectedItems.includes(item);

  return (
    <Modal
      title={`Select ${_.capitalize(buttonType.toLowerCase())}`}
      visible={visible}
      onCancel={handleClose}
      className="custom-modal"
      footer={[
        <div key="footer" className='brand-modal-footer'>
          <div className='brand-modal-footer-div' >
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              onClick={handleClearSelection}
              className='brand-modal-footer-button'
            />
            <Badge
              count={
                <span>
                  <span className='brand-modal-footer-badge1'>{selectedItems.length}</span>
                  <span className='brand-modal-footer-badge2'> selected</span>
                </span>
              }
              className='brand-modal-footer-badge'
            />
          </div>
          <Button key="apply" type="primary" onClick={handleApply} className='brand-modal-footer-apply-button'>
            Apply
          </Button>
        </div>
      ]}
    >
      <div className='brand-modal-sub-header'>
        <Checkbox
          onChange={handleSelectAll}
          checked={selectAll}
          className="custom-checkbox"
        >
          Select All
        </Checkbox>
        <Input
          placeholder="Search here.."
          value={searchTerm}
          onChange={handleSearchChange}
          className='brand-modal-search-bar'
          prefix={<SearchOutlined className='brand-modal-search-prefix' />}
        />
      </div>
      <hr className="brand-modal-hr" />
      <div className="brand-list">
        {filteredItems?.map((item) => (
          <div
            key={item}
            className="brand-row"
          >
            <Checkbox
              checked={isChecked(item)}
              onChange={() => handleCheckboxChange(item)}
              className="custom-checkbox"
            >
              <span className="brand-row-text">{item}</span>
            </Checkbox>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default BrandModal;
