import React from 'react';
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css"
import InsightStocks from "../../components/InventoryInsightComponents/InsightStocks";
import ExpectedStockoutTable from '../../components/InventoryInsightComponents/ExpectedStockoutTable';
import OpportunityLostGraph from '../../components/InventoryInsightComponents/OpportunityLostGraph';

function InventoryInsightsPage() {
  return (
    <div className='dashboard_main_container'>

            <InsightStocks />
            <OpportunityLostGraph />


            <ExpectedStockoutTable />

            {/* <EmptyState img={NodataImg} data="Please apply filters for view the data & charts" page="products" /> */}
        </div>
  )
}

export default InventoryInsightsPage