import { combineReducers } from '@reduxjs/toolkit';
import sampleReducer from '../sample/sampleSlice';
import userReducer from '../Login/UserSlice';
import modalReducer from '../Login/Modal/modalSlice';
import DemandAnalysisReducer from '../DemandAnalysis/DemandAnalysisSlice';
import productReducer from '../Product/ProductSlice';
import DashboardReducer from '../Dashboard/DashboardSlice';
import UserManagementReducer from '../UseMangementSlice';
import assortmentReducer from '../Assortment/AssortmentSlice'; // Assuming this is the existing assortment slice
import assortmentModalReducer from '../Assortment/AssortmentModalSlice'; // New assortment modal slice
import { LOGOUT } from '../../app/action';
import inventoryInsightsReducer from '../InventoryInsights/InventoryInsightsSlice';


const rootReducer = combineReducers({
    sample: sampleReducer,
    user: userReducer,
    modal: modalReducer,
    demand_analysis: DemandAnalysisReducer,
    products: productReducer,
    dashboard: DashboardReducer,
    userManagement: UserManagementReducer,
    assortment: assortmentReducer, // Existing assortment slice
    assortmentModal: assortmentModalReducer,
    inventoryInsights: inventoryInsightsReducer // New assortment modal slice
});

const resettableRootReducer = (state = {}, action) => {
    if (action.type === LOGOUT) {
      const newState = {};
      Object.keys(state).forEach(key => {
        newState[key] = rootReducer[key].undefined; // Reset each reducer's state to its initial state
      });
      return newState;
    }
    return rootReducer(state, action);
  };

export default resettableRootReducer;
