import React from 'react';

function GraphFilters({ handleSpecialDayHover }) {
    const graphInfo = [
        { name: 'Actual', color: '#7B61FF', style: 'solid' },
        { name: 'Bestfit Forecast', color: '#FF4444', style: 'dotted' },
        { name: 'Prophet', color: '#2AC239', style: 'dotted' },
        { name: 'ETS', color: '#7b61ff', style: 'solid' }
    ];


    const specialDaysData = [
        // { name: 'Chinese New Year', color: '#2AC2394D' },
        { name: 'New Year', color: '#00C1F9' },
        // { name: 'Good Friday', color: '#00C1F9' },
        // { name: 'Christmas', color: '#215CA24D' },
        // { name: 'Hari Raya Puasa', color: '#FF45EC4D' },
        // { name: 'Labour Day', color: '#e4afb1' },
        // { name: 'Vesak Day', color: '#A6D21287' },
        // { name: 'Hari Raya Haji', color: '#B2ACD6' },
        // { name: 'National Day', color: '#B24CD6' },
        { name: 'Diwali', color: '#B3BC46' },
        { name: "Prophet's Ascension", color: '#DAD7D7' },
        { name: "Prophet's Birthday", color: '#FD98E7' },
        // { name: 'Ramadan', color: '#F4F0F0' },
        { name: 'CNY', color: '#ff927f' },
        // { name: 'Eid al-Fitr', color: '#E9D2D2' },
        // { name: 'Day of Arafat/ EID', color: '#FAEBEB' },
        // { name: 'Islamic New Year', color: '#FCE8E8' },
        // { name: 'Commemoration Day & National Day', color: '#F5F0F0' },
        // { name: 'Qatar National Day', color: '#F3E6F1' },
        // { name: 'Qatar National Sports Day', color: '#EFE6F0' },
        // { name: 'National Day/Liberation Day', color: '#F7F0EE' }
    ];
    const handleDayHover = (day) => {
        handleSpecialDayHover(day);
    };
    return (
        <div className='graph-filters-main'>
            <div className='graph-info'>
                {graphInfo.map((info) => (
                    <div key={info.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                        <div
                            style={{
                                width: '30px',
                                height: '2px',
                                borderBottom: `2px ${info.style} ${info.color}`,
                                marginRight: '8px'
                            }}
                        ></div>
                        <div>{info.name}</div>
                    </div>
                ))}
            </div>
            <div className='graph-days-filters'>
                {specialDaysData.map((day) => (
                    <div
                        key={day.name}
                        className='special-day-item'
                        onMouseEnter={() => handleDayHover(day)}
                        onMouseLeave={() => handleDayHover(null)}
                    >
                        <div
                            style={{
                                width: '20px',
                                height: '20px',
                                background: `${day.color}`,
                                marginRight: '8px'
                            }}
                        ></div>
                        <div>{day.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default GraphFilters;
