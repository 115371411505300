import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profile } from '../../features/Login/UserSlice'; // Import your profile action
import Sidebar from './Sidebar'; // Import your Sidebar component

const SidebarContainer = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.user);
  const [userType, setUserType] = useState('');
  const [designation, setDesignation] = useState('');

  useEffect(() => {
    // Dispatch the profile action if userId is available
    
    dispatch(profile());
    
  }, [dispatch]);

  useEffect(() => {
    if (userProfile) {
      setUserType(userProfile.user_type || '');
      setDesignation(userProfile.designation || '');
    }
  }, [userProfile]);

  return <Sidebar userType={userType} designation={designation} />;
};

export default SidebarContainer;
