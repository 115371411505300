import React from 'react';
import ValianceLogo from '../../assets/SVG/valiancelogo1.svg';
import mainLogo from '../../assets/Images/LoginPageLogo.png'
import { ConfigProvider, Form, Input, Button, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

function ForgotPasswordPage() {
  const [email, setEmail] = React.useState('');
  const navigate = useNavigate();

  const onFinish = (values) => {
    // console.log('Received values of form: ', values);
    message.success('Password reset link has been sent to your email');
    navigate('');
  };

  return (
    <main>
      <div className="login">
      <div className='login-content-main-logo'>
          <img src={mainLogo} alt="logo" />
        </div>
        <div className='login-content-header-logo'>
          <img src={ValianceLogo} alt="Valiance-logo" />
        </div>
        <div className='login-content-heading'>
          <h2>ForeSight</h2>
        </div>

        <div className='login-content'>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: "#172935",
                  defaultBg: "#172935",
                  colorPrimaryHover: "#919191",
                },
              },
            }}
          >
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              layout="vertical"
            >
              <Form.Item
                className='username_box'
                name="email"
                label="Please provide an email address."
                rules={[{ required: true, message: "Please input your Email!" }, { type: 'email', message: "Please enter a valid Email!" }]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                  style={{ color: "white" }}
                >
                  Send
                </Button>
                <Form.Item style={{ marginBottom: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <Link to="/">Back to Login</Link>
                  </div>
                </Form.Item>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>

        <div className='login-content-footer-centreside'>
          <p>© 2024 Valiance Solutions.</p>
        </div>
        <div className='login-content-footer-rightside'>
          <p>Privacy policy | Cookie policy</p>
        </div>
      </div>
    </main>
  )
}

export default ForgotPasswordPage;
