import React, { useState } from 'react';
import { Modal, Input, Button, Form, Select, Typography, Radio, Space } from 'antd';
import BarChartImage from '../assets/Images/BarChart.png'; // Adjust image path as necessary
import LineChartImage from '../assets/Images/LineChart.png'; // Adjust image path as necessary
import PieChartImage from '../assets/Images/PieChart.png'; // Adjust image path as necessary
import Group66 from '../assets/Images/Group66.png'; // Adjust image path as necessary
import ChartSettingsModal from './ChartSettingsModal'; // Import the combined modal

const { Option } = Select;
const { Title } = Typography;

function AddChartModal({ visible, onCancel }) {
    const [selectedChartType, setSelectedChartType] = useState(null);
    const [isChartModalVisible, setIsChartModalVisible] = useState(false);

    const handleChartTypeChange = (e) => {
        setSelectedChartType(e.target.value);
    };

    const handleNext = () => {
        if (selectedChartType) {
            setIsChartModalVisible(true);
        }
    };

    const handleCloseChartModal = () => {
        setIsChartModalVisible(false);
    };

    const labelStyle = {
        fontSize: '16px',
        color: '#7B61FF',
        fontWeight: '600',
    };

    const formItemStyle = {
        marginBottom: '16px', // Slightly more margin for better spacing
    };

    const radioButtonStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',  // Increased height for better image and text display
        width: '120px',   // Increased width for symmetry
        borderRadius: '8px',
        padding: '10px',
        textAlign: 'center',
        backgroundColor: '#FFFFFF',  // White background for radio buttons
        border: '1px solid #D9D9D9', // Border styling
        cursor: 'pointer',
        position: 'relative',  // Position relative for absolute positioning of the background component
    };

    const backgroundStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#F1EFFF',
        borderRadius: '8px',
        zIndex: '-1', // Place it behind the content
    };

    const imageStyle = {
        marginBottom: '8px',
        height: '80px',  // Adjusted height for better visibility
        width: '80px',   // Adjusted width for better visibility
    };

    return (
        <>
            <Modal
                visible={visible}
                title={(
                    <span style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                        <img style={{ height: "4rem", width: "4rem" }} src={Group66} alt="Chart Icon" />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ color: "#288CD5", fontSize: '20px', fontWeight: 'bold' }}>Creating Chart - First Step</div>
                            <p style={{ color: "#919191", fontSize: "15px", fontWeight: "400", lineHeight: "20px" }}>
                                Design a chart tailored to your needs to monitor the specific data you want to track.
                            </p>
                        </div>
                    </span>
                )}
                onCancel={onCancel}
                footer={null}
            >
                <Form layout="vertical" onFinish={handleNext}>
                    <Form.Item
                        name="ChartTitle"
                        label={<span style={labelStyle}>Chart Title</span>}
                        style={formItemStyle}
                        rules={[{ required: true, message: 'Please input the Chart title!' }]}
                    >
                        <Input placeholder="Enter title here" />
                    </Form.Item>

                    <Form.Item
                        name="SelectDataSource"
                        label={<span style={labelStyle}>Data Set</span>}
                        style={formItemStyle}
                    >
                        <p
                            style={{
                                color: '#919191',
                                fontSize: '15px',
                                fontWeight: '400',
                                marginBottom: '12px',
                            }}
                        >
                            Create a chart using Sales, Forecast, Inventory, and Sales Assortments data.
                        </p>
                        <Select placeholder="Sales">
                            <Option value="sales">Sales</Option>
                            <Option value="forecast">Forecast</Option>
                            <Option value="inventory">Inventory</Option>
                            <Option value="assortments">Sales Assortments</Option>
                        </Select>
                    </Form.Item>

                    <Title level={5} style={labelStyle}>Chart Type</Title>

                    <p
                        style={{
                            color: '#919191',
                            fontSize: '15px',
                            fontWeight: '400',
                            marginBottom: '12px',
                        }}
                    >
                        Select a chart type: bar, line, or pie. Modify details in the next step.
                    </p>

                    <Form.Item
                        name="ChartType"
                        rules={[{ required: true, message: 'Please select a chart type' }]}
                        style={formItemStyle}
                    >
                        <Radio.Group onChange={handleChartTypeChange} style={{ width: '100%' }}>
                            <Space size="large" wrap>
                                <Radio.Button value="bar chart" style={radioButtonStyle}>
                                    <div style={backgroundStyle}></div> {/* Background component */}
                                    <img src={BarChartImage} alt="Bar Chart" style={imageStyle} />
                                    <span style={{ color: '#7B61FF', fontWeight: '500', fontSize: '14px' }}>Bar Chart</span>
                                </Radio.Button>

                                <Radio.Button value="line chart" style={radioButtonStyle}>
                                    <div style={backgroundStyle}></div> {/* Background component */}
                                    <img src={LineChartImage} alt="Line Chart" style={imageStyle} />
                                    <span style={{ color: '#7B61FF', fontWeight: '500', fontSize: '14px' }}>Line Chart</span>
                                </Radio.Button>

                                <Radio.Button value="pie chart" style={radioButtonStyle}>
                                    <div style={backgroundStyle}></div> {/* Background component */}
                                    <img src={PieChartImage} alt="Pie Chart" style={imageStyle} />
                                    <span style={{ color: '#7B61FF', fontWeight: '500', fontSize: '14px' }}>Pie Chart</span>
                                </Radio.Button>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item>
                        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={onCancel}
                                style={{ background: '#F5F5F5', color: 'black' }}
                            >
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Next
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {selectedChartType && (
                <ChartSettingsModal
                    visible={isChartModalVisible}
                    onCancel={handleCloseChartModal}
                    chartType={selectedChartType}
                />
            )}
        </>
    );
}

export default AddChartModal;
