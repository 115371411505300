import React, { useState, useEffect } from 'react'; 
import Chart from 'react-apexcharts';
import { Spin, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenModal from '../CommonComponents/FullscreenModal';
import { fetchOpportunityLost } from '../../features/InventoryInsights/InventoryInsightsSlice'; // Import the thunk

const { Option } = Select;

const OpportunityLostPlot = () => {
    const dispatch = useDispatch();
    const { opportunityLostData, loading } = useSelector((state) => state.inventoryInsights);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState('All'); // New state for selected item/product
    const [selectedLocation, setSelectedLocation] = useState('All');
    const [selectedForecastType, setSelectedForecastType] = useState('ets_forecast');

    useEffect(() => {
        dispatch(fetchOpportunityLost({ filterName: '', filter: {}, selectedItems: [] })); // Dispatch the thunk to fetch data
    }, [dispatch]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleItemChange = (value) => {
        setSelectedItem(value); // Set selected item/product
    };

    const handleLocationChange = (value) => {
        setSelectedLocation(value);
    };

    const handleForecastTypeChange = (value) => {
        setSelectedForecastType(value);
    };

    // Filter data based on selected item/product and location
    const actualData = opportunityLostData?.actual?.filter(item =>
        (selectedItem === 'All' || item.product_item_sku === selectedItem) &&
        (selectedLocation === 'All' || item.location === parseInt(selectedLocation))
    ) || [];

    const forecastData = opportunityLostData?.forecast?.filter(item =>
        (selectedItem === 'All' || item.product_item_sku === selectedItem) &&
        (selectedLocation === 'All' || item.location === parseInt(selectedLocation))
    ) || [];

    const salesData = actualData.map(item => ({
        x: new Date(item.report_date).getTime(),
        y: item.sales
    }));

    const forecastSalesData = forecastData.map(item => ({
        x: new Date(item.report_date).getTime(),
        y: item[selectedForecastType]
    }));

    const series = [
        {
            name: 'Actual Sales',
            data: salesData
        },
        {
            name: selectedForecastType === 'ets_forecast' ? 'ETS Forecast Sales' : 'Prophet Forecast Sales',
            data: forecastSalesData
        }
    ];

    const options = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true
            },
            toolbar: {
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: [{
                        icon: '<span>&#x26F6;</span>',
                        index: 4,
                        title: 'Fullscreen',
                        class: 'custom-fullscreen',
                        click: function () {
                            showModal();
                        }
                    }]
                }
            }
        },
        colors: ['#7B61FF', '#FF4444'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [3, 3],
            curve: 'smooth',
            dashArray: [0, 8]
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'MMM dd',
            },
        },
        yaxis: {
            title: {
                text: 'Sales'
            },
            labels: {
                formatter: function (value) {
                    return value >= 1000 ? value / 1000 + 'K' : value;
                }
            }
        },
        tooltip: {
            shared: true,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                const seriesName = seriesIndex === 1 ? 'Forecast Sales' : 'Actual Sales';
                return `<div class="tooltip">
                          <span><strong>Date:</strong> ${new Date(data.x).toDateString()}</span><br/>
                          <span><strong>${seriesName}:</strong> ${data.y}</span>
                        </div>`;
            }
        }
    };

    // Extract unique items/products for the dropdown
    const items = Array.from(new Set([...actualData, ...forecastData].map(item => item.product_item_sku)));

    // Extract unique locations for the dropdown
    const locations = Array.from(new Set([...actualData, ...forecastData].map(item => item.location)));

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                {/* Item/Product Dropdown */}
                <Select defaultValue="All" style={{ width: 200 }} onChange={handleItemChange}>
                    <Option value="All">All Items</Option>
                    {items.map(item => (
                        <Option key={item} value={item}>Item {item}</Option>
                    ))}
                </Select>

                {/* Location Dropdown */}
                <Select defaultValue="All" style={{ width: 200, marginLeft: '10px' }} onChange={handleLocationChange}>
                    <Option value="All">All Locations</Option>
                    {locations.map(location => (
                        <Option key={location} value={location.toString()}>Location {location}</Option>
                    ))}
                </Select>

                {/* Forecast Type Dropdown */}
                <Select defaultValue="ets_forecast" style={{ width: 200, marginLeft: '10px' }} onChange={handleForecastTypeChange}>
                    <Option value="ets_forecast">ETS Forecast</Option>
                    <Option value="prophet_forecast">Prophet Forecast</Option>
                </Select>
            </div>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Chart options={options} series={series} type="line" height={350} />
                    <FullscreenModal visible={isModalVisible} onClose={handleClose} title="Forecasted Graph" options={options} series={series} type="line" height={350} />
                </>
            )}
        </div>
    );
};

export default OpportunityLostPlot;
