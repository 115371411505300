import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const fetchMyChartData = createAsyncThunk(
    'myCharts/fetchMyChartData',
    async (params, thunkAPI) => {
        const { dataset, chart_type } = params;
        
        // Construct the endpoint URL
        const endpoint = `forecasting/my_chart/?dataset=${dataset}&chart_type=${chart_type}`;
        
        // Make the request
        const request = await makeRequest("GET", endpoint);
        const response = await request.data;
        
        return response;
    }
);

const MyChartsSlice = createSlice({
    name: "myCharts",
    initialState: {
        status: 'idle',
        myChartData: [],
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyChartData.pending, (state) => {
                state.status = 'loading';
                state.myChartData = []
            })
            .addCase(fetchMyChartData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.myChartData = action.payload;
            })
            .addCase(fetchMyChartData.rejected, (state) => {
                state.status = 'failed';
                state.myChartData = []
            });
    },
});

export const selectMyChartData = (state) => state.myCharts.myChartData;

export default MyChartsSlice.reducer;
