import React, { useState, useEffect } from 'react';
import { Card, Table, Select, Spin, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInventoryInsights } from '../../features/InventoryInsights/InventoryInsightsSlice';
import forecastedataIcon from '../../assets/SVG/icons/DemandAnalysis/forecastedataicon.svg';
import CardsHeading from '../../components/CommonComponents/CardsHeadingComponent';

const { Option } = Select;

const columns = [
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
  {
    title: 'Overall Inventory',
    dataIndex: 'overall_inventory',
    key: 'overall_inventory',
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
  {
    title: 'Sold',
    dataIndex: 'sales',
    key: 'sales',
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
  {
    title: 'In Stock',
    dataIndex: 'in_stock',
    key: 'in_stock',
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
];

function InsightStocks() {
  const dispatch = useDispatch();
  const { inventoryInsightsData, loading, error } = useSelector((state) => state.inventoryInsights);
  const [categoryFilter, setCategoryFilter] = useState(null);

  useEffect(() => {
    dispatch(fetchInventoryInsights());
  }, [dispatch]);

  const handleCategoryFilterChange = (value) => {
    setCategoryFilter(value); 
  };

  const filteredData = (inventoryInsightsData || []).filter(item => !categoryFilter || item.category === categoryFilter);

  return (
    <Card
      className='dashboard_card_containers'
      style={{ marginBottom: "20px" }}
    >
      <div className='forecasted_data_main'>
        <CardsHeading
          heading="Inventory Positions"
          headingicon={forecastedataIcon}
          dataTODownload={inventoryInsightsData}
          showFilter={false}
          componentType="inventorytype"
        />
        {loading ? (
          <Spin size="large" />
        ) : error ? (
          <Alert message="Error" description={error} type="error" showIcon />
        ) : (
          <div>
            <Select
              style={{ width: 200, marginBottom: 16 }}
              placeholder="Select category"
              onChange={handleCategoryFilterChange}
              allowClear
            >
              {Array.from(new Set((inventoryInsightsData || []).map(item => item.category))).map(category => (
                <Option key={category} value={category}>{category}</Option>
              ))}
            </Select>
            <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 5 }} />
          </div>
        )}
      </div>
    </Card>
  );
}

export default InsightStocks;