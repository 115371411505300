import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'antd';
import CardsHeading from '../CommonComponents/CardsHeadingComponent';
import forecastedchartIcon from '../../assets/SVG/icons/DemandAnalysis/forecastedcharticon.svg';
import { useDispatch, useSelector } from "react-redux";
import OpportunityLostPlot from './OpportunityLostPlot';
function OpportunityLostGraph() {
    const [loading, setLoading] = useState(true);
    const chartRef = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Card
            className=' dashboard_card_containers'
            loading={loading}
            style={{ marginBottom: "20px" }}

        >
            <CardsHeading heading="Opportunity Lost Graph" headingicon={forecastedchartIcon} dataTODownload={chartRef} showFilter={false} componentType="opportunityLostGraph" />


            <div ref={chartRef} style={{ backgroundColor: 'white' }}>
              <OpportunityLostPlot />
            </div>
        </Card>
    );
}

export default OpportunityLostGraph;
