import React, { useState, useEffect } from 'react';
import Graph from './graph/Graph';
import ForecastTable from './ForecastTable';
import { Select, Row, Col } from 'antd';
import "../css/allocation.css";
import mainData from "../assets/custom_data.json"

const { Option } = Select;

const AllocationPlanning = () => {
  // Sample data for brands and countries
  const [brands, setBrands] = useState([]);
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState([]);

  // State for selected brand and country
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    // Use the imported mainData directly
    setData(mainData);

    // Extract brands and countries from the data
    const uniqueBrands = [...new Set(mainData.map(item => item['Item.[L7]']))];
    const uniqueCountries = [...new Set(mainData.map(item => item['Location.[Location Country]']))];
    setBrands(uniqueBrands);
    setCountries(uniqueCountries);

    // Set default selections
    setSelectedBrand(uniqueBrands[0]);
    setSelectedCountry(uniqueCountries[0]);
  }, []);

  // Filtered data based on selected brand and country
  const filteredData = data.filter(
    item => item['Item.[L7]'] === selectedBrand && item['Location.[Location Country]'] === selectedCountry
  );

  return (
    <div></div>
    // <div className='allcation_container_main'>
    //   <Row gutter={[16, 16]}>
    //     <Col className="select-container">
    //       <label htmlFor="brand-select">Select Brand:</label>
    //       <Select id="brand-select" value={selectedBrand} onChange={setSelectedBrand}>
    //         {brands.map(brand => (
    //           <Option key={brand} value={brand}>
    //             {brand}
    //           </Option>
    //         ))}
    //       </Select>
    //     </Col>
    //     <Col className="select-container">
    //       <label htmlFor="country-select">Select Country:</label>
    //       <Select id="country-select" value={selectedCountry} onChange={setSelectedCountry}>
    //         {countries.map(country => (
    //           <Option key={country} value={country}>
    //             {country}
    //           </Option>
    //         ))}
    //       </Select>
    //     </Col>
    //   </Row>
    //   <div className="graph-container" style={{ marginTop: '50px' }}>
    //     <h5>Forecast Plot</h5>
    //     <Graph data={filteredData} />
    //   </div>
    //   <div className="forecast-table-container" style={{ marginTop: '50px' }}>
    //     <h5>Forecast Data</h5>
    //     <ForecastTable data={filteredData} />
    //   </div>
    // </div>
  );
};

export default AllocationPlanning;
