import React, { useEffect, useState } from 'react';
import { Card, Table, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SimilarItemsIcon from '../../assets/SVG/icons/Products/SimilarItemsIcon.svg';
import CardsHeading from '../../components/CommonComponents/CardsHeadingComponent';
import { fetchExpectedStockout } from '../../features/InventoryInsights/InventoryInsightsSlice';

const { Option } = Select;

const columns = [
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
  {
    title: 'Expected Stock Out',
    dataIndex: 'expected_stock_out', 
    key: 'expected_stock_out', 
    onHeaderCell: () => ({
      style: { color: '#919191' }
    }),
    render: (text) => (
      <div style={{ color: '#7B61FF' }}>{text}</div>
    ),
  },
];

function ExpectedStockoutTable() {
  const dispatch = useDispatch();
  
  // Accessing data from the Redux store
  const { expectedStockoutData, loading, error } = useSelector((state) => state.inventoryInsights);
  const [categoryFilter, setCategoryFilter] = useState(null);

  // Fetch expected stockout data on component mount
  useEffect(() => {
    dispatch(fetchExpectedStockout());
  }, [dispatch]);

  // Handle category filter change
  const handleCategoryFilterChange = (value) => {
    setCategoryFilter(value); // Update selected category filter
  };

  // Filter data based on selected category
  const filteredData = categoryFilter ? expectedStockoutData.filter(item => item.category === categoryFilter) : expectedStockoutData;

  return (
    <Card className='dashboard_card_containers' loading={loading}>
      <div className='forecasted_data_main'>
        <CardsHeading 
          heading="Expected Stock Out" 
          headingicon={SimilarItemsIcon} 
          dataTODownload={expectedStockoutData} 
          showFilter={false} 
          componentType="expectedstockout" 
        />
        <div style={{ marginBottom: 16 }}>
          <Select
            style={{ width: 200 }}
            placeholder="Select category"
            onChange={handleCategoryFilterChange}
            allowClear
          >
            {/* Generate options based on unique categories in the fetched data */}
            {expectedStockoutData.length > 0 && Array.from(new Set(expectedStockoutData.map(item => item.category))).map(category => (
              <Option key={category} value={category}>{category}</Option>
            ))}
          </Select>
        </div>
        {/* Show error if there's any */}
        {error && <p style={{ color: 'red' }}>Error: {error}</p>}
        <Table dataSource={filteredData} columns={columns} pagination={{ pageSize: 5 }} />
      </div>
    </Card>
  );
}

export default ExpectedStockoutTable;
