import React, { useState, useEffect } from 'react';
import { Card, Table } from 'antd';
import CardsHeading from '../CommonComponents/CardsHeadingComponent';
import { useSelector } from 'react-redux';

// Updated columns definition
const columns = [
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
    },


    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Top Brand',
        dataIndex: 'top_brand',
        key: 'top_brand',
    },

    {
        title: 'Sales Value',
        dataIndex: 'sales_value',
        key: 'sales_value',
    }
];

function SellingProducts() {
    const { topSellingProductsData } = useSelector((state) => state.dashboard || []);
    const [loading, setLoading] = useState(true);

    // console.log(topSellingProductsData);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    // Map the data to include keys for each entry
    const mappedData = topSellingProductsData.map((item, index) => ({
        ...item,
        key: index.toString()
    }));

    return (
        <Card
            className='dashboard_card_containers sellingproducts_main_container'
            loading={loading}
        >
            <CardsHeading heading="Top Brand in Country" dataTODownload={topSellingProductsData} showFilter={true} componentType="topSellingProducts" />
            <Table
                className='sellingproducts_table'
                columns={columns}
                dataSource={mappedData}
                pagination={{ pageSize: 10 }}
                rowClassName={(record, index) => 'custom-row-class'}
            />
        </Card>
    );
}

export default SellingProducts;
