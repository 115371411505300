import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

const buildQueryParams = (state, includeLevelFilter = true) => {
    let queryParams = [];
    if (state.filter && state.filter !== 'Set Filter') {
        queryParams.push(`filter_type=${encodeURIComponent(state.filter)}`);
    }
    if (state.itemFilter.length > 0 && state.itemFilter[0] !== "All") {
        queryParams.push(...state.itemFilter.map(item => `product=${encodeURIComponent(item)}`));
    }
    if (state.locationFilter.length > 0 && state.locationFilter[0] !== "All") {
        queryParams.push(...state.locationFilter.map(item => `location=${encodeURIComponent(item)}`));
    }
    if (includeLevelFilter && state.levelFilter) {
        queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
    }

    return queryParams.join('&'); 
};

export const fetchSimilarItems = createAsyncThunk(
    'products/fetchSimilarItems',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = "";

        switch(params.filterName) {
            case "item":
                thunkAPI.dispatch(setItemFilter(params.selectedItems));
                break;
            case "location":
                thunkAPI.dispatch(setLocationFilter(params.selectedItems));
                break;
                default:
                thunkAPI.dispatch(setFilter('Set Filter'));
                thunkAPI.dispatch(setItemFilter([]));
                thunkAPI.dispatch(setLocationFilter([]));
                break;  
        }

        const state = thunkAPI.getState();
        joint = buildQueryParams(state.products, false); // Do not include levelFilter
        endpoint = `customer/similar-items/?${joint}`;
        console.log(`Fetching similar items with endpoint: ${endpoint}`); // Debug log
        const request = await makeRequest("get", endpoint);
        return request.data;
    }
);

export const fetchChartData = createAsyncThunk(
    'products/fetchChartData',
    async (params, thunkAPI) => {
        let endpoint = "";
        let joint = ""; 

        switch(params.filterName) {
            case "duration":
                thunkAPI.dispatch(setFilter(params.filter.payload));
                break;
            case "item":
                thunkAPI.dispatch(setItemFilter(params.selectedItems));
                break;
            case "location":
                thunkAPI.dispatch(setLocationFilter(params.selectedItems));
                break;
            case "level":
                thunkAPI.dispatch(setLevelFilter(params.filter.filterType));
                break;
                default:
                thunkAPI.dispatch(setFilter('Set Filter'));
                thunkAPI.dispatch(setItemFilter([]));
                thunkAPI.dispatch(setLocationFilter([]));
                break;

        }

        const state = thunkAPI.getState();
        joint = buildQueryParams(state.products, true); // Include levelFilter
        endpoint = `customer/product-forecast-trend/?${joint}`;
        console.log(`Fetching chart data with endpoint: ${endpoint}`); // Debug log
        console.log(`Query parameters: ${joint}`); // Debug log
        const request = await makeRequest("get", endpoint);
        console.log(`API Response: ${request.data}`); // Debug log
        return request.data;
    }
);

const productSlice = createSlice({
    name: "products",
    initialState: {
        status: 'idle',
        similarItems: [],
        chartData: [],
        itemFilter: ["All"],
        locationFilter: ["All"],
        selectedItem: [],
        selectedLocation: [],
        selectedLevel: "Monthly",
        levelFilter: "Monthly",
        filter: 'Set Filter',
    },
    reducers: {
        setItemFilter: (state, action) => {
            state.itemFilter = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },

        setLocationFilter: (state, action) => {
            state.locationFilter = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload;
        },
        setLevelFilter: (state, action) => {
            state.levelFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSimilarItems.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSimilarItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.similarItems = action.payload;
            })
            .addCase(fetchSimilarItems.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchChartData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchChartData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.chartData = action.payload;
            })
            .addCase(fetchChartData.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setFilter, setItemFilter, setLocationFilter, setSelectedItem, setSelectedLocation, setLevelFilter } = productSlice.actions;

export default productSlice.reducer;
