import React, { useEffect } from 'react';
import "../../css/Dashboard/dashboard.css";
import SalesAnalysis from '../../components/DashboardComponents/SalesAnalysis';
import CategoryAnalysis from '../../components/DashboardComponents/CategoryAnalysis';
import SalesMonitor from '../../components/DashboardComponents/SalesMonitor';
import SellingProducts from '../../components/DashboardComponents/SellingProducts';

import { overallAnalysisApi, categoryAnalysisApi, salesMonitorApi, topSellingProductsApi, salesAnalysisGrowthApi } from "../../features/Dashboard/DashboardSlice";
import { useDispatch } from "react-redux";

function DashboardPage() {

    const dispatch = useDispatch();

    useEffect(() => {

        // if (status === 'idle') {
        dispatch(overallAnalysisApi(''));
        dispatch(categoryAnalysisApi(''));
        dispatch(salesMonitorApi(''));
        dispatch(topSellingProductsApi(''));
        dispatch(salesAnalysisGrowthApi(''));
        // }
    }, [dispatch]);
    return (
        <div className='dashboard_main_container'>
            <div className='dashboard_top_container'>
                <SalesAnalysis />
                <CategoryAnalysis />
            </div>
            {/* <div className='dashboard_bottom_containers'> */}
            <SalesMonitor />
            <SellingProducts />
            {/* </div> */}
        </div>
    )
}

export default DashboardPage;