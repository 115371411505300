import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const createNewEntry =  createAsyncThunk(
    'onboarding/createNewEntry',
    async (values,thunkAPI) => {
        let endpoint = "";
        const state = thunkAPI.getState();
        const userType = localStorage.getItem('userType')
        endpoint = `${userType}/onboarding_end_customer_handler_${userType}/`; 
        console.log(`Creating new entry with endpoint: ${endpoint}`); // Debug log

        // // create a formdata object from the values
        // const formData = new FormData();
        // for(const key in values){
        //     formData.append(key, values[key]);
        // }
        // console.log("form data",formData)
        const request = await makeRequest("post", endpoint, values);
        console.log("Request data", request.data);
        return request.data;

    }
)

export const fetchOnboardingData = createAsyncThunk(
    'onboarding/fetchOnboardingData',
    async (param, thunkAPI) => {
        console.log(`Fetching data with parameters: `, param); // Debug log

        const userType = localStorage.getItem('userType');
        let endpoint = `${userType}/get_onboarded_${userType}/`;

        if (param && param.length > 0) {
            const queryParams = new URLSearchParams(param[0]).toString(); // Convert dictionary to query string
            endpoint += `?${queryParams}`; // Append query params to endpoint
        }

        console.log(`Fetching data from endpoint: ${endpoint}`); // Debug log
        const request = await makeRequest("get", endpoint);
        console.log("Request data of fetchOnboardingData", request.data);
        return request.data;
    }
);





export const fetchCustomerDetails = createAsyncThunk(
    'onboarding/fetchCustomerDetails',
    async(params,thunkAPI) =>{
        console.log("Fetching customer details with id: ", params);
        const userType = localStorage.getItem('userType')
        let endpoint = "";
        if(params !== ''){
            endpoint += (params+'/');
        }

        console.log("endpoint", endpoint);
        const request = await makeRequest("get", `${userType}/view_details_${userType}_onboarded_user/${endpoint}`);
        console.log("request is", request);
        const response = await request.data;
        console.log("inside fetch Customer details", response);
        return response;
    }
)

export const updateCustomerDetails = createAsyncThunk(
    'onboarding/updateCustomerDetails',
    async (values, thunkAPI) => {
        console.log("Update customer details with values: ", values);

        const userType = localStorage.getItem('userType');
        const { id, ...valuesWithoutId } = values; // Remove id from the values object
        
        // Construct the endpoint URL using the id
        const endpoint = id; // Use the id as the endpoint
        const request = await makeRequest("patch", `${userType}/update_${userType}_onboarded_user_details/${endpoint}/`, valuesWithoutId);

        console.log("Request is", request);
        const response = await request.data;
        console.log("Inside fetch Customer details", response);

        return response;
    }
);

export const deleteCustomerDetails = createAsyncThunk(
    'onboarding/deleteCustomerDetails',
    async(params,thunkAPI) =>{
        const userType = localStorage.getItem('userType')
        console.log("paramas",params,userType)
        const request = await makeRequest("delete", `${userType}/onboarding_${userType}_delete_user/`,params);
        console.log("request is", request);
        const response = await request.data;
        console.log("inside fetch Customer details", response);
        return response;
    }
)

const OnboardingSlice = createSlice({
    name:"onboarding",
    initialState: {
        status: 'idle',
        error: null,
        Onboardingdata: [],
        customerDetails:[],
        filterCustomerDetails:[],
        createNewEntryStatus: 'idle',
        updateCustomerDetailsStatus: 'idle',
        deleteCustomerDetailsStatus: 'idle',
    },

    reducers: {
        setOnboardingData: (state, action) => {
            state.Onboardingdata = action.payload;
        },
        setCustomerDetails : (state, action) =>{
            state.customerDetails = action.payload;
        },
        setFilterCustomerDetails : (state, action) =>{
            state.filterCustomerDetails = action.payload;
        },
        resetOnboardingState: (state) => {
           
            state.createNewEntryStatus = null;
            state.deleteCustomerDetailsStatus = null;
            state.updateCustomerDetailsStatus = null;
        },
    },

    extraReducers: (builder) => {
        builder
        .addCase(createNewEntry.pending, (state) => {
            state.createNewEntryStatus = 'loading'
        })
        .addCase(createNewEntry.fulfilled, (state, action) => {
            state.createNewEntryStatus = 'succeeded';
            // state.Onboardingdata.push(action.payload);
        })
        .addCase(createNewEntry.rejected, (state, action) => {
            state.createNewEntryStatus = 'failed'
            state.error = action.error.message;
        })
        .addCase(fetchOnboardingData.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchOnboardingData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.Onboardingdata = action.payload;
        })
        .addCase(fetchOnboardingData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchCustomerDetails.pending, (state) => {
            // state.status = 'loading';

        })
        .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
            // state.status = 'succeeded';
            state.customerDetails = action.payload;
        })
        .addCase(fetchCustomerDetails.rejected, (state, action) => {
            // state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(deleteCustomerDetails.pending, (state) => {
            state.deleteCustomerDetailsStatus = 'loading';

        })
        .addCase(deleteCustomerDetails.fulfilled, (state, action) => {
            state.deleteCustomerDetailsStatus = 'succeeded';
        })
        .addCase(deleteCustomerDetails.rejected, (state, action) => {
            state.deleteCustomerDetailsStatus = 'failed';
            state.error = action.error.message;
        })
        .addCase(updateCustomerDetails.pending, (state) => {
            state.updateCustomerDetailsStatus = 'loading';

        })
        .addCase(updateCustomerDetails.fulfilled, (state, action) => {
            state.updateCustomerDetailsStatus = 'succeeded';
            // state.customerDetails = action.payload;
        })
        .addCase(updateCustomerDetails.rejected, (state, action) => {
            state.updateCustomerDetailsStatus = 'failed'
            state.error = action.error.message;
        })
    }
})
      export const { setOnboardingData , setCustomerDetails, setFilterCustomerDetails, resetOnboardingState } = OnboardingSlice.actions;

export default OnboardingSlice.reducer;