import React, { useState, useEffect } from 'react';
import { Table, Pagination, Card } from 'antd';
import { useSelector } from "react-redux";
import forecastedataIcon from '../../assets/SVG/icons/DemandAnalysis/forecastedataicon.svg';
import CardsHeading from '../CommonComponents/CardsHeadingComponent';

function ForecastSetupTable() {
    const { forecastSetupData } = useSelector((state) => state.forecast_setup || []);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 7;

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    const columns = [
        {
            title: 'Task Id',
            dataIndex: 'task_id',
            key: 'task_id',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text}</div>
            ),
        },
        {
            title: 'Details',
            children: [
                {
                    title: 'Baseline Model',
                    dataIndex: ['details', 'baseline_model'],
                    key: 'baseline_model',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{text}</div>
                    ),
                },
                {
                    title: 'Rollback Model',
                    dataIndex: ['details', 'rollback_model'],
                    key: 'rollback_model',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{text}</div>
                    ),
                },
                {
                    title: 'Forecast Models',
                    dataIndex: ['details', 'forecast_models'],
                    key: 'forecast_models',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{Array.isArray(text) ? text.join(', ') : text}</div>
                    ),
                }
            ]
        },
        {
            title: 'Task Description',
            children: [
                {
                    title: 'Forecast Period',
                    dataIndex: ['description', 'forecast_period'],
                    key: 'forecast_period',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{text}</div>
                    ),
                },
                {
                    title: 'Validation Period',
                    dataIndex: ['description', 'validation_period'],
                    key: 'validation_period',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{text}</div>
                    ),
                },
                {
                    title: 'Error Metric',
                    dataIndex: ['description', 'error_metric'],
                    key: 'error_metric',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{text}</div>
                    ),
                },
                {
                    title: 'Cross Validation',
                    dataIndex: ['description', 'cross_validation'],
                    key: 'cross_validation',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{text}</div>
                    ),
                },
                {
                    title: 'Forecast Time Level',
                    dataIndex: ['description', 'forecast_time_level'],
                    key: 'forecast_time_level',
                    render: (text) => (
                        <div style={{ color: '#7B61FF' }}>{text}</div>
                    ),
                }

            ]
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text}</div>
            ),
        },
        {
            title: 'Final Date',
            dataIndex: 'end_date',
            key: 'end_date',
            onHeaderCell: () => ({
                style: { color: '#919191' }
            }),
            render: (text) => (
                <div style={{ color: '#7B61FF' }}>{text}</div>
            ),
        }
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const currentPageData = forecastSetupData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <Card className='dashboard_card_containers' loading={loading}>
            <div className='forecasted_data_main'>
                <CardsHeading heading="Forecast Setup Data" headingicon={forecastedataIcon} dataTODownload={forecastSetupData} showFilter={false} componentType="forecastSetupTable" />
                <div>
                    <Table
                        columns={columns}
                        dataSource={currentPageData}
                        pagination={false}
                        rowKey="task_id"
                    />
                    <Pagination
                        current={currentPage}
                        total={forecastSetupData.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        style={{ marginTop: '16px', textAlign: 'left' }}
                    />
                </div>
            </div>
        </Card>
    );
}

export default ForecastSetupTable;
