import React from 'react';
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css";
import ProfileArea from "../../components/ProfileComponents/ProfileArea";
// import EmptyState from '../../components/CommonComponents/EmptyStateComponent';
// import NoDataImg from "../../assets/Images/NoData.png"

function AssortmentPage() {
    return (
        <div style={{height: "95%", margin: "10px", padding: "10px"}}>
            <ProfileArea />

            {/* <EmptyState img={NoDataImg} data="Please apply filters for view the data & charts" page="assortment"/> */}
        </div>
    )
}

export default AssortmentPage;