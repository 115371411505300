import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userIcon from '../../assets/SVG/Navbar/Usericon.svg';
import editImgIcon from '../../assets/SVG/icons/Profile/edit_user_img.svg';
import editIcon from '../../assets/SVG/icons/Profile/edit_profile.svg';
import { Row, Col, Button, Input, message } from 'antd';
import CrownImgIcon from '../../assets/SVG/icons/Profile/solar_crown-broken.svg';
import backgroundImage from '../../assets/SVG/icons/Profile/bgimg.svg'
import { profile, updatePassword, uploadUserImage, updateUserDetails } from '../../features/Login/UserSlice';
import ProfilePicModal from '../../Modal/ProfilePicModal';
import { useNavigate } from 'react-router-dom';

function ProfileArea() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.user.user);
  const passwordAPIStatus = useSelector((state) => state.user.passwordAPIStatus);
  const imageUploadStatus = useSelector((state) => state.user.imageUploadStatus);
  const updatedImageUrl = useSelector((state) => state.user.updatedImageUrl);
  const [isPersonalDetailsEditable, setIsPersonalDetailsEditable] = useState(false);
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);
  

  const [initialFirstName, setInitialFirstName] = useState("");
  const [initialLastName, setInitialLastName] = useState("");
  const [initialDesignation, setInitialDesignation] = useState("");
  const [initialEmail, setInitialEmail] = useState("");
  const [initialContactNumber, setInitialContactNumber] = useState("");
  const [initialOrganization, setInitialOrganization] = useState("");
  const [initialCustomerName, setInitialCustomerName] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [organization, setOrganization] = useState("");
  const [customerName, setCustomerName] = useState([]);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [profilePicture, setProfilePicture] = useState(userIcon);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(profile())
  }, [dispatch]);

  useEffect(() => {
    if (userProfile) {
      // const personalDetails = userProfile["Personal Details"] || {};
      // const profileDetails = userProfile["Profile Details"] || {};
      console.log("userProfile",userProfile)
      setInitialFirstName(userProfile.first_name || "");
      setInitialLastName(userProfile.last_name || "");
      setInitialDesignation(userProfile.designation || "");
      setInitialEmail(userProfile.email || "");
      setInitialContactNumber(userProfile.contact || "");
      setInitialOrganization(userProfile.organization || "");
      // setInitialCustomerName(userProfile.customer_name || []);

      setFirstName(userProfile.first_name || "");
      setLastName(userProfile.last_name || "");
      setDesignation(userProfile.designation || "");
      setEmail(userProfile.email || "");
      setContactNumber(userProfile.contact || "");
      setProfilePicture(userProfile.profile_img || userIcon);
      setOrganization(userProfile.organization || "");
      // setCustomerName(profileDetails.customer_name || []);
    }
  }, [userProfile]);

  useEffect(() => {
    if (imageUploadStatus === 'succeeded' && updatedImageUrl) {
      setProfilePicture(updatedImageUrl);
      message.success("Profile picture updated successfully");
    } else if (imageUploadStatus === 'failed') {
      message.error("Failed to update profile picture");
    }
  }, [imageUploadStatus, updatedImageUrl]);

  const handleProfilePictureChange = (file) => {
    console.log("file",file.size)
    if (file.size < 1024 * 1024) { // Ensure file is below 1 MB
      dispatch(uploadUserImage(file))
        .then((response) => {
          if (response.type === 'user/uploadUserImage/fulfilled') {
            setIsModalVisible(false);
            dispatch(profile())
            message.success("Profile picture update successfully");

          } else {
            message.error("Failed to update profile picture");
          }
        });
    } else {
      message.error("File size should be less than 1 MB");
    }
    return false; // Prevent automatic upload
  };

  const savePersonalDetails = () => {
    const userDetails = {
      firstName,
      lastName,
      designation,
      email,
      contactNumber
    };

    dispatch(updateUserDetails(userDetails))
      .then((response) => {
        if (response.type === 'user/updateUserDetails/fulfilled') {
          message.success("Personal details updated successfully");
          setInitialFirstName(firstName);
          setInitialLastName(lastName);
          setInitialDesignation(designation);
          setInitialEmail(email);
          setInitialContactNumber(contactNumber);
          setInitialOrganization(organization);
          // setInitialCustomerName(customerName);
          setIsPersonalDetailsEditable(false);
        } else {
          message.error("Failed to update personal details");
        }
      });
  };

  const discardPersonalDetailsChanges = () => {
    setFirstName(initialFirstName);
    setLastName(initialLastName);
    setDesignation(initialDesignation);
    setEmail(initialEmail);
    setContactNumber(initialContactNumber);
    setOrganization(initialOrganization);
    // setCustomerName(initialCustomerName);
    setIsPersonalDetailsEditable(false);
  };

  const savePassword = () => {
    if (newPassword !== confirmNewPassword) {
      message.error("New Password and Confirm New Password do not match");
      return;
    }

    dispatch(updatePassword({ currentPassword, newPassword, confirmNewPassword }))
      .then((response) => {
        if (response.type === 'user/updatePassword/fulfilled') {
          discardPasswordChanges();
          message.success("Password updated successfully").then(() =>{
            setTimeout(() => {
              navigate('/');
            }, 2500);
          });
          
        } else {
          message.error("Failed to update password");
        }
      });
  };

  const discardPasswordChanges = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setIsPasswordEditable(false);
  };

  const inputStyle = (editable) => ({
    background: editable ? '#FFFFFF' : '#F6F5FF',
    border: editable ? '1px solid #7B61FF' : '1px solid #7B61FF'
  });

  return (
    <div className='user__profile__main'>
      <div className="user__profile__container">
        <div className="user__profile__elem1">
          <div className="user__profile__img__edit">
            <label htmlFor="profilePictureInput" onClick={() => setIsModalVisible(true)}>
              <img style={{ cursor: 'pointer' }} src={editImgIcon} alt="edit" />
            </label>
          </div>
          <div className="user__profile__img__box">
            <div className="user__profile__img">
              <img src={profilePicture} alt="user" />
            </div>
          </div>
          <div className="user__profile__name__box">
            <span>{initialFirstName} {initialLastName}<img src={CrownImgIcon} alt="img" /></span>
            <span>{initialDesignation}</span>
          </div>
          <div className="user__profile__other__detail__box">
            <div className="user__profile__detail">
              <span>Email ID</span>
              <span>{initialEmail}</span>
            </div>
            <div className="user__profile__detail">
              <span>Contact Number</span>
              <span>{initialContactNumber}</span>
            </div>
            <div className="user__profile__detail">
              <span>Organization</span>
              <span>{initialOrganization}</span>
            </div>
          </div>
        </div>
        <div className="user__profile__elem2">
          {/* Personal Details Section */}
          <div className="user__profile__personal__detail__box1">
            <div className="user__detail__heading">
              <span>Personal Details</span>
              <button onClick={() => setIsPersonalDetailsEditable(!isPersonalDetailsEditable)}>
                <img src={editIcon} alt="edit" />
              </button>
            </div>
            <div className="user__detail__form">
              <Row gutter={[24, 24]} justify="space-between">
                {/* First Row: First Name, Last Name, and Designation */}
                <Col className="col" xs={24} md={8}>
                  <label htmlFor="firstName">First Name</label>
                  <Input
                    placeholder={firstName}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={!isPersonalDetailsEditable}
                    style={inputStyle(isPersonalDetailsEditable)}
                  />
                </Col>
                <Col className="col" xs={24} md={8}>
                  <label htmlFor="lastName">Last Name</label>
                  <Input
                    placeholder=""
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={!isPersonalDetailsEditable}
                    style={inputStyle(isPersonalDetailsEditable)}
                  />
                </Col>
                <Col className="col" xs={24} md={8}>
                  <label htmlFor="designation">Designation</label>
                  <Input
                    placeholder=""
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    disabled={!isPersonalDetailsEditable}
                    style={inputStyle(isPersonalDetailsEditable)}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} justify="space-between">
                {/* Second Row: Email ID and Contact Number */}
                <Col className="col" xs={24} md={12}>
                  <label htmlFor="email">Email ID</label>
                  <Input
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={true}
                    style={inputStyle(false)}
                  />
                </Col>
                <Col className="col" xs={24} md={12}>
                  <label htmlFor="contactNumber">Contact Number</label>
                  <Input
                    placeholder=""
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    disabled={!isPersonalDetailsEditable}
                    style={inputStyle(isPersonalDetailsEditable)}
                  />
                </Col>
              </Row>
            </div>

            {isPersonalDetailsEditable && (
              <div className="user__profile__button__box">
                <button onClick={savePersonalDetails}>Save</button>
                <button onClick={discardPersonalDetailsChanges}>Discard</button>
              </div>
            )}
          </div>
          
          {/* Change Password Section */}
          <div className="user__profile__personal__detail__box2">
            <div className="user__detail__heading">
              <span>Change Password</span>
              <button onClick={() => setIsPasswordEditable(!isPasswordEditable)}>
                <img src={editIcon} alt="edit" />
              </button>
            </div>
            <form>
              <Row gutter={[2, 2]} justify="start">
                <Col className="col" xs={24} md={10}>
                  <div className="input-wrapper">
                    <label htmlFor="currentPassword">Current Password</label>
                    <Input.Password
                      placeholder="******"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      disabled={!isPasswordEditable}
                      style={inputStyle(isPasswordEditable)}
                    />
                  </div>
                </Col>
                <Col className="col" xs={24} md={10}>
                  <div className="input-wrapper">
                    <label htmlFor="newPassword">New Password</label>
                    <Input.Password
                      placeholder="******"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      disabled={!isPasswordEditable}
                      style={inputStyle(isPasswordEditable)}
                    />
                  </div>
                </Col>
                <Col className="col" xs={24} md={10}>
                  <div className="input-wrapper">
                    <label htmlFor="confirmNewPassword">Confirm New Password</label>
                    <Input.Password
                      placeholder="******"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      disabled={!isPasswordEditable}
                      style={inputStyle(isPasswordEditable)}
                    />
                  </div>
                </Col>
              </Row>

            </form>
            {isPasswordEditable && (
              <div className="user__profile__button__box">
                <button onClick={savePassword}>Save</button>
                <button onClick={discardPasswordChanges}>Discard</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ProfilePicModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSave={handleProfilePictureChange}
      />
    </div>
  );
}

export default ProfileArea;
