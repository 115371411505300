import React from 'react'
import { Routes, Route } from 'react-router';
import SidebarContainer from './components/SideBarComponent/SidebarContainer'
import AllocationPlanning from './components/AllocationPlanning';
import InSeasonAllocation from './components/InSeasonAllocation';
import LiverpoolAllocation from './components/LiverpoolAllocation'
import UserManagementPage from './pages/UserMangement/UserManagementPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import Navbar from './components/Navbar';
//import DemandAnalysisPage from './pages/DemandAnalysis/DemandAnalysisPage';

import DemandAnalysisPage from './pages/DemandAnalysis/DemandAnalysisPage';
import AssortmentPage from './pages/Assortment/AssortmentPage';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import OnboardingPage from './pages/OnboardingPage/OnboardingPage';
import ProfilePage from './pages/Profile/ProfilePage';
import MyCharts from './pages/MyCharts/MyChartPage';
import ForecastSetupPage from './pages/ForecastSetupPage/ForecastSetupPage';
import InventoryInsightsPage from './pages/InventoryInsights/InventoryInsightsPage'
function Main() {
    return (
        <>
            {/* <div style={{ width: "100vw", background: "black", height: "5vh" }}>TopNavBar</div> */}
            {/* <div className='main_container'> */}
            {/* <div className='main_sidebar'>
                    <SidebarContainer />
                </div> */}
            <div className='dashboard_main'>
                <div className='dashboard_navbar'>
                    <Navbar />
                </div>
                <div className='dashboard_sidebar'>
                    <SidebarContainer />
                    <div className='main_elements'>
                        <Routes>
                            <Route path='/allocationplanning' element={<AllocationPlanning />} />
                            <Route path='/mycharts' element={<MyCharts />} />

                            <Route path='/demand-analysis' element={<DemandAnalysisPage />} />
                            <Route path='/usermanagement' element={<UserManagementPage />} />

                            <Route path='/demand-analysis' element={<DemandAnalysisPage />} />
                            <Route path='/assortment' element={<AssortmentPage />} />
                            <Route path='/products' element={<ProductsPage />} />
                            <Route path='/inventory-insights' element={<InventoryInsightsPage />} />

                            <Route path='/dashboard' element={<DashboardPage />} />


                            <Route path='/onboarding' element={<OnboardingPage />} />

                            <Route path='/profile' element={<ProfilePage />} />
                            <Route path='/forecast-setup' element={<ForecastSetupPage />} />
                        </Routes>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}
export default Main