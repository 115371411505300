import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import Draggable from 'react-draggable';
import { Card } from 'antd';
import { Resizable } from 'react-resizable';

function PieChart({ row = [], col = [] }) {
    const [expanded, setExpanded] = useState(false);
    const [size, setSize] = useState({ width: 450, height: 350 });

    const toggleExpand = () => {
        setExpanded(prevExpanded => !prevExpanded);
    };

    const onResize = (event, { size }) => {
        setSize(size);
    };

    // Define a color mapping for the categories
    const colorMapping = {
        'APPAREL': '#7B61FF',
        'FOOTWEAR': '#2AC239',
        'ACCESSORIES': '#00C1F9'
    };

    // Generate the colors array based on the categories
    const colors = row.map(category => colorMapping[category] || undefined);

    const options = {
        chart: {
            type: 'donut',  // Set chart type to 'donut' to create a pie chart with an inner radius
        },
        labels: row,
        colors: colors,  // Use the generated colors array
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 100
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        plotOptions: {
            pie: {
                donut: {
                    size: '67%'  // Adjust this value to set the inner radius
                }
            }
        }
    };

    const series = col;

    return (
        <Draggable handle=".handle">
            <Resizable
                width={size.width}
                height={size.height}
                minConstraints={[300, 300]}
                maxConstraints={[800, 800]}
                onResize={onResize}
                resizeHandles={['se']}
            >
                <Card
                    className="handle"
                    style={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                        transition: 'transform 0.3s ease',
                        transform: expanded ? 'scale(1.1)' : 'scale(1)',
                        cursor: 'move',
                        zIndex: expanded ? 999 : 'auto', // Ensure expanded card stays on top
                        width: size.width + 'px',
                        height: size.height + 'px',
                    }}
                    onClick={toggleExpand}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Chart options={options} series={series} type="donut" width={size.width} height={size.height} />
                    </div>
                </Card>
            </Resizable>
        </Draggable>
    );
}

export default PieChart;
