import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Form, Select, Switch } from 'antd';
import '../../../css/Onboarding/OnboardingEntryModal.css'; // Import the CSS file

const { Option } = Select;


const countryCodes = [
    { name: "US", code: "+1" },
    { name: "IN", code: "+91" },
    { name: "UK", code: "+44" },
    { name: "CA", code: "+1" },
    { name: "AU", code: "+61" },
    { name: "DE", code: "+49" },
    { name: "NL", code: "+31" },
    { name: "FR", code: "+33" },
    { name: "CH", code: "+41" },
    { name: "SE", code: "+46" },
    { name: "JP", code: "+81" },
    { name: "CN", code: "+86" },
    { name: "SG", code: "+65" },
    { name: "HK", code: "+852" },
    { name: "KR", code: "+82" },
    { name: "SA", code: "+966" },
    { name: "AE", code: "+971" },
    { name: "QA", code: "+974" },
    { name: "BH", code: "+973" },
    { name: "KW", code: "+965" },
    { name: "OM", code: "+968" },
    { name: "ZA", code: "+27" },
    { name: "BR", code: "+55" },
    { name: "MX", code: "+52" },
    { name: "AR", code: "+54" },
    { name: "CL", code: "+56" },
    { name: "CO", code: "+57" },
    { name: "PE", code: "+51" },
    { name: "MY", code: "+60" },
    { name: "TH", code: "+66" },
    { name: "ID", code: "+62" },
    { name: "VN", code: "+84" },
    { name: "PH", code: "+63" },
    { name: "IL", code: "+972" },
    { name: "TR", code: "+90" },
    { name: "EG", code: "+20" },
    { name: "NG", code: "+234" },
    { name: "KE", code: "+254" },
    { name: "BD", code: "+880" },
    { name: "LK", code: "+94" },
    { name: "NP", code: "+977" }
];

const countryOptions = countryCodes.map(country => ({
    value: country.code,
    label: `${country.name} (${country.code})`
}));

const OnboardingEntryModal = ({ visible, onCancel, onCreate, onUpdate, customerDetails, mode }) => {
    const userType = localStorage.getItem('userType');
    const [countryCode, setCountryCode] = useState("+91"); // Default country code

    const renderOptions = () => {
        if (userType === 'root') {
            return (
                <>
                    <Option value="partner">Partner</Option>
                    <Option value="end-customer">End Customer</Option>
                </>
            );
        } else if (userType === 'partner') {
            return <Option value="end-customer">End Customer</Option>;
        }
        return null;
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if (customerDetails && mode === "update") {
            const formValues = {
                name: customerDetails.name,
                email: customerDetails.email,
                contact: customerDetails.contact,
                business_address: customerDetails.business_address,
                is_active: customerDetails.is_active,
                user_type: customerDetails.user_type,
            };

            if (mode === 'update' && customerDetails.id) {
                formValues.id = customerDetails.id;
            }

            if (mode === 'update' && customerDetails.database_name) {
                formValues.database_name = customerDetails.database_name;
            }

            if (mode === 'update' && customerDetails.onboarding_status) {
                formValues.onboarding_status = customerDetails.onboarding_status;
            }

            form.setFieldsValue(formValues);
        }
    }, [customerDetails, form, mode]);

    useEffect(() => { 
        if (visible) { 
            form.resetFields(); 
        } 
    }, [visible, form]);

    const getCountryCode = (contactNumber) => {
        const contactStr = contactNumber ? String(contactNumber) : "";
        const matchedCountryCode = countryCodes.find(({ code }) => contactStr.startsWith(code));
        return matchedCountryCode ? matchedCountryCode.code : "+91"; 
    };

    const removeCountryCode = (contactNumber) => {
        const contactStr = contactNumber ? String(contactNumber) : "";
        const matchedCountryCode = countryCodes.find(({ code }) => contactStr.startsWith(code));
        if (matchedCountryCode) {
            return contactStr.slice(matchedCountryCode.code.length).trim();
        }
        return contactStr;
    };

    useEffect(() => {
        if (customerDetails && customerDetails.contact && mode === "update") {
            const currentCountryCode = getCountryCode(customerDetails.contact); 
            setCountryCode(currentCountryCode);
            const contactWithoutCode = removeCountryCode(customerDetails.contact);
            form.setFieldsValue({ contact: currentCountryCode + contactWithoutCode });
        }
    }, [customerDetails, form]);

    useEffect(() => {
        if (visible && mode === "update") {
            const contact = form.getFieldValue("contact") || "";
            const contactWithoutCode = removeCountryCode(contact);
            form.setFieldsValue({ contact: countryCode + contactWithoutCode });
        }
    }, [visible, countryCode, form]);

    const handleCountryCodeChange = (value) => {
        setCountryCode(value);
        const contact = form.getFieldValue("contact") || "";
        const contactWithoutCode = removeCountryCode(contact);
        form.setFieldsValue({ contact: value + contactWithoutCode });
    };

    const handleContactChange = (event) => {
        const contactNumber = event.target.value;
        if (contactNumber) {
            const contactWithoutCode = removeCountryCode(contactNumber);
            form.setFieldsValue({ contact: countryCode + contactWithoutCode });
        } else {
            form.setFieldsValue({ contact: countryCode });
        }
    };

    const handleCreateOrUpdate = () => {
        if (mode === 'update') {
            return onUpdate;
        } else if (mode === 'create') {
            return onCreate;
        }
    };

    return (
        <Modal
            visible={visible}
            title={mode === 'create' ? 'Create new entry' : (mode === 'update' ? 'Update details' : '')}
            onCancel={onCancel}
            footer={null}
            width={500}
            bodyStyle={{ padding: 0 }} 
        >
            <div className="modal-body">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleCreateOrUpdate()}
                    className="modal-content"
                >
                    <Form.Item name="id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={<span className="label-style">Brand title</span>}
                        rules={[{ required: true, message: 'Please input the brand title!' }]}
                    >
                        <Input className="input-style" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={<span className="label-style">Email</span>}
                        rules={[{ required: true, message: 'Please input the email!' }]}
                    >
                        <Input type="email" className="input-style" />
                    </Form.Item>
                    <Form.Item
                        name="contact"
                        label={<span className="label-style">Contact no.</span>}
                        rules={[{ required: true, message: 'Please input the contact number!' }]}
                    >
                        <Input
                            className="contact-number-style"
                            onChange={handleContactChange}
                            addonBefore={
                                <Select placeholder="select" defaultValue={"+91"} value={countryCode} onChange={handleCountryCodeChange} options={countryOptions} className="country-code-style" required />
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="business_address"
                        label={<span className="label-style">Business address</span>}
                        rules={[{ required: true, message: 'Please input the business address!' }]}
                    >
                        <Input className="input-style" />
                    </Form.Item>
                    <Form.Item
                        name="is_active"
                        label={<span className="label-style">Active Status</span>}
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="user_type"
                        label={<span className="label-style">User Type</span>}
                        rules={[{ required: true, message: 'Please select the user type!' }]}
                    >
                        <Select placeholder="Select a user type" className="select-style">
                            {renderOptions()}
                        </Select>
                    </Form.Item>
                    {mode === 'update' && (
                        <Form.Item
                            name="database_name"
                            label={<span className="label-style">Database</span>}
                            rules={[{ required: true, message: 'Add Database' }]}
                        >
                            <Input className="input-style" />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="onboarding_status"
                        label={<span className="label-style">Onboarding Status</span>}
                        rules={[{ required: true, message: 'Please select the onboarding status!' }]}
                    >
                        <Select placeholder="Select a Status of Onboarding" className="select-style">
                            <Option value="pending">pending</Option>
                            <Option value="approve">approve</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="button-style">
                            {mode === 'create' ? 'Apply' : (mode === 'update' ? 'Update' : 'Submit')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default OnboardingEntryModal;
