import React from 'react';
import Plot from 'react-plotly.js';

const ForecastPlot = ({ data }) => {
  // Helper function to safely access properties
  const safeAccess = (item, key) => item && item[key] ? item[key] : null;

  // Actual and forecast data based on filtered data (adjust as needed)
  const actualData = {
    x: data.map(item => safeAccess(item, 'Time.[WeekKey]')).filter(x => x !== null), // Access week data
    y: data.map(item => item.DP009CategoryActual).filter(y => y !== null), // Access actual sales data
    type: 'scatter',
    mode: 'lines',
    name: 'Actual',
    line: { color: 'rgb(49, 51, 63)' }
  };

  // Placeholder for forecast data (adjust as needed)
  const lgbmData = {
    x: data.map(item => safeAccess(item, 'Time.[WeekKey]')).filter(x => x !== null), // Access week data
    y: data.map(item => safeAccess(item, 'Category MLng Fcst LGBM')).filter(y => y !== null), // Access LGBM forecast data
    type: 'scatter',
    mode: 'lines',
    name: 'ML Fcst LGBM',
    line: { dash: 'dot', color: 'red' }
  };

  const catBoostData = {
    x: data.map(item => safeAccess(item, 'Time.[WeekKey]')).filter(x => x !== null), // Access week data
    y: data.map(item => safeAccess(item, 'Category MLng Fcst CatBoost')).filter(y => y !== null), // Access CatBoost forecast data
    type: 'scatter',
    mode: 'lines',
    name: 'ML Fcst CatBoost',
    line: { dash: 'dash', color: 'blue' }
  };

  // Calculate tickvals and ticktext
  const tickCount = 7; // Including first and last, adjust as needed for more or fewer ticks
  const tickStep = Math.floor(data.length / (tickCount - 1));
  const tickIndices = Array.from({ length: tickCount }, (_, i) => i * tickStep).slice(0, tickCount - 1).concat(data.length - 1);
  const tickvals = tickIndices.map(index => safeAccess(data[index], 'Time.[WeekKey]')).filter(x => x !== null);
  const ticktext = tickIndices.map(index => safeAccess(data[index], 'Time.[WeekKey]')).filter(x => x !== null);

  const layout = {
    xaxis: {
      title: 'Time in Weeks',
      tickvals: tickvals,
      ticktext: ticktext
    },
    yaxis: { title: 'Sales' }
  };

  return (
    <Plot
      style={{ width: '90%', height: '100%' }}
      data={[actualData, lgbmData, catBoostData]}
      layout={layout}
    />
  );
};

export default ForecastPlot;
