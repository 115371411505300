import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { makeRequest } from "../../api/api";
import { hostname } from "../../Data";


export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (userCredentials) => {
        try {
            const response = await axios.post(`${hostname}auth/login/`, userCredentials);
            const token = response.data.access;
            localStorage.setItem("token", token);
            return { user: "user", token };
        } catch (error) {
            throw error;
        }
    }
);

export const profile = createAsyncThunk(
    'user/profile',
    async () => {
        try {
            const token = localStorage.getItem("Authorization");
            // Remove "Bearer " prefix if it exists
            const cleanToken = token ? token.replace(/^Bearer\s*/, '') : token;
            // Optionally, remove any extra spaces around the token
            const trimmedToken = cleanToken ? cleanToken.trim() : cleanToken;
            
            if (!trimmedToken) {
                throw new Error('Token not found');
            }
            const response = await makeRequest("get", `${hostname}auth/get_user_details/`);
            console.log(response.data)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

// export const profile = createAsyncThunk(
//     'products/profile',
//     async () => {
//         const response = await makeRequest("get", `${hostname}auth/get_user_details/`);
//         return response.data;
//     }
// );

export const updatePassword = createAsyncThunk(
    'user/updatePassword',
    async ({ currentPassword, newPassword, confirmNewPassword }) => {
        try {
            const formdata = new FormData();
            formdata.append("current_password", currentPassword);
            formdata.append("new_password", newPassword);
            formdata.append("confirm_new_password", confirmNewPassword);

            const response = await makeRequest("patch", `${hostname}auth/reset-password/`, formdata);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const uploadUserImage = createAsyncThunk(
    'user/uploadUserImage',
    async (file) => {
        try {
            const formdata = new FormData();
            formdata.append("profile_img", file);
            console.log("file",file)
            const token = localStorage.getItem("Authorization");
            if (!token) {
                throw new Error('Token not found');
            }
            // Remove "Bearer " prefix if it exists
            const cleanToken = token ? token.replace(/^Bearer\s*/, '') : token;
            // Optionally, remove any extra spaces around the token
            const trimmedToken = cleanToken ? cleanToken.trim() : cleanToken;

            const response = await axios.post(
                `${hostname}root/upload_profile_image_in_gcp/`,
                formdata,
                {
                    headers: {
                        "Authorization": trimmedToken
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const updateUserDetails = createAsyncThunk(
    'user/updateUserDetails',
    async (userDetails) => {
        try {
            const formdata = new FormData();
            formdata.append("first_name", userDetails.firstName);
            formdata.append("last_name", userDetails.lastName);
            formdata.append("designation", userDetails.designation);
            // formdata.append("email", userDetails.email);
            formdata.append("contact", userDetails.contactNumber);

            const token = localStorage.getItem("Authorization");
            if (!token) {
                throw new Error('Token not found');
            }
            // Remove "Bearer " prefix if it exists
            const cleanToken = token ? token.replace(/^Bearer\s*/, '') : token;
            // Optionally, remove any extra spaces around the token
            const trimmedToken = cleanToken ? cleanToken.trim() : cleanToken;

            const response = await axios.patch(
                `${hostname}auth/edit_user_details/`,
                formdata,
                {
                    headers: {
                        "Authorization": trimmedToken
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const signupUser = createAsyncThunk(
    'signup',
    async (userCredentials) => {
        try {
            const response = await makeRequest("post", `${hostname}signup`, userCredentials);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);



const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        user: null,
        error: null,
        login: false,
        // profileData: null,
        passwordAPIStatus: null,
        imageUploadStatus: null,
        updatedImageUrl: null,
        updateUserStatus: null,
    },
    reducers: {
        restoreLogin: (state) => {
            const storedToken = localStorage.getItem("Authorization");
            const user = localStorage.getItem("user")
            if (storedToken && user) {
                state.login = true;
                state.user = JSON.parse(user)
            }
            else {
                state.login = false
                state.user = null
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = null
                state.error = null;
                state.login = true;
                localStorage.setItem("Authorization", `Bearer ${action.payload.token}`);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.login = false;
                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid Credentials";
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(profile.pending, (state) => {
                state.user = null;
            })
            .addCase(profile.fulfilled, (state, action) => {
                state.user = action.payload.data
                localStorage.setItem("user", JSON.stringify(action.payload.data));

            })
            .addCase(profile.rejected, (state, action) => {
                state.user = null;
                localStorage.removeItem("user");
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.passwordAPIStatus = true;
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.passwordAPIStatus = false;
            })
            .addCase(uploadUserImage.pending, (state) => {
                state.imageUploadStatus = 'loading';
            })
            .addCase(uploadUserImage.fulfilled, (state, action) => {
                state.imageUploadStatus = 'succeeded';
                state.updatedImageUrl = action.payload.updated_image_url;
            })
            .addCase(uploadUserImage.rejected, (state, action) => {
                state.imageUploadStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateUserDetails.pending, (state) => {
                state.updateUserStatus = 'loading';
            })
            .addCase(updateUserDetails.fulfilled, (state, action) => {
                state.updateUserStatus = 'succeeded';
                state.user = action.payload.data;
            })
            .addCase(updateUserDetails.rejected, (state, action) => {
                state.updateUserStatus = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { restoreLogin } = userSlice.actions;

export default userSlice.reducer;


