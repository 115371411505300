import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Input, Button, Form, Select, Typography, Radio } from 'antd';
import ForecastingSetupIcon from '../../../assets/SVG/icons/Forecasting setup/ForecastingsetupIcon.svg';
import { postForecastSetupApi, forecastSetupApi } from "../../../features/ForecastSetup/ForecastSetupSlice";
const { Option } = Select;
const { Title } = Typography;

const ForecastingSetupModal = ({ visible, onCancel, setIsModalVisible }) => {
    const dispatch = useDispatch();
    const labelStyle = {
        fontSize: '12px',
        color: '#7B61FF',
    };

    const formItemStyle = {
        marginBottom: '12px', // reduce margin-bottom for Form.Item
    };

    const onCreate = (values) => {
        // console.log(values)
        dispatch(postForecastSetupApi(values));
        dispatch(forecastSetupApi(''));
        setIsModalVisible(false);
    }

    return (
        <Modal
            destroyOnClose={true}
            visible={visible}
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={ForecastingSetupIcon} alt="Forecasting setup" style={{ marginRight: '16px' }} />
                    Forecasting setup
                </div>
            }
            onCancel={onCancel}
            footer={null}
        >
            <Form
                layout="vertical"
                onFinish={onCreate}
            >
                <Title level={5} style={labelStyle}>Select Baseline model</Title>
                <Form.Item
                    name="selectBaselinemodel"
                    rules={[{ required: true, message: 'Please select the Baseline model' }]}
                    style={formItemStyle}
                >
                    <Select placeholder="select here...">
                        <Option value="model1">Historical Average</Option>
                        <Option value="model2">Window Average</Option>
                        <Option value="model3">Naive</Option>
                    </Select>
                </Form.Item>

                <Title level={5} style={labelStyle}>Select Forecast model</Title>
                <Form.Item
                    name="selectForecastmodel"
                    rules={[{ required: true, message: 'Please select the Forecast model' }]}
                    style={formItemStyle}
                >
                    <Select mode="multiple" placeholder="select here...">
                        <Option value="modelA">Auto ARIMA</Option>
                        <Option value="modelB">Auto ETS</Option>
                        <Option value="modelC">Dynamic Optimized Theta</Option>
                        <Option value="modelD">Seasonal Naive</Option>
                        <Option value="modelE">Croston</Option>
                    </Select>
                </Form.Item>

                <Title level={5} style={labelStyle}>Select Fallback model</Title>
                <Form.Item
                    name="selectfallbackmodel"
                    rules={[{ required: true, message: 'Please select the Fallback model' }]}
                    style={formItemStyle}
                >
                    <Select placeholder="select here...">
                        <Option value="fallback1">Window Average</Option>
                        <Option value="fallback2">Seasonal Naive</Option>
                    </Select>
                </Form.Item>

                <div style={{ display: "flex", gap: "110px" }}>
                    <div style={{ width: "50%" }}>
                        <Title level={5} style={labelStyle}>Forecast period</Title>
                        <Form.Item
                            name="forecastperiod"
                            rules={[{ required: true, message: 'Please enter the Forecast period' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder='eg .20' />
                        </Form.Item>
                    </div>

                    <div style={{ width: "50%" }}>
                        <Title level={5} style={labelStyle}>Validation period</Title>
                        <Form.Item
                            name="validationperiod"
                            rules={[{ required: true, message: 'Please enter the validation period' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder='eg .10' />
                        </Form.Item>
                    </div>
                </div>

                <div style={{ display: "flex", gap: "110px" }}>
                    <div style={{ width: "50%" }}>
                        <Title level={5} style={labelStyle}>Error Metric</Title>
                        <Form.Item
                            name="errormetric"
                            rules={[{ required: true, message: 'Please select the Error metric' }]}
                            style={formItemStyle}
                        >
                            <Radio.Group>
                                <Radio value="RMSE">RMSE</Radio>
                                <Radio value="MSE">MSE</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <div style={{ width: "50%" }}>
                        <Title level={5} style={labelStyle}>Cross Validations</Title>
                        <Form.Item
                            name="crossvalidations"
                            rules={[{ required: true, message: 'Please select the Cross Validations' }]}
                            style={formItemStyle}
                        >
                            <Radio.Group>
                                <Radio value="1">1</Radio>
                                <Radio value="2">2</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>

                <Title level={5} style={labelStyle}>Forecast Time Level</Title>
                <Form.Item
                    name="forecasttimelevel"
                    rules={[{ required: true, message: 'Please select the Forecast Time level' }]}
                    style={formItemStyle}
                >
                    <Radio.Group>
                        <Radio value="Weekly">Weekly</Radio>
                        <Radio value="Monthly">Monthly</Radio>
                        <Radio value="Daily">Daily</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item style={formItemStyle}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
                        <Button onClick={onCancel} type="primary" style={{ backgroundColor: '#EFEFEF', color: '#172935' }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#7B61FF', color: '#FFFFFF' }}>
                            Save
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ForecastingSetupModal;
