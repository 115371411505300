import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../features/RootReducer/rootreducer';
import UserReducer from '../features/Login/UserSlice';

import modalReducer from '../features/Login/Modal/modalSlice';
import DemandAnalysisReducer from '../features/DemandAnalysis/DemandAnalysisSlice';
import productReducer from '../features/Product/ProductSlice';
import DashboardReducer from '../features/Dashboard/DashboardSlice';
import OnboardingReducer from '../features/Onboarding/OnboardingSlice';
import userManagementReducer from '../features/UseMangementSlice';
import ForecastSetupReducer from '../features/ForecastSetup/ForecastSetupSlice';
import myChartsReducer from '../features/MyCharts/MyChartsSlice';
import assortmentReducer from '../features/Assortment/AssortmentSlice'; // Existing assortment slice
import assortmentModalReducer from '../features/Assortment/AssortmentModalSlice'; // New assortment modal slice
import filterReducer from '../features/Filters/FiltersSlice';
import inventoryInsightsReducer from '../features/InventoryInsights/InventoryInsightsSlice';


const store = configureStore({
  reducer: {
    rootReducer,
    user: UserReducer,
    modal: modalReducer,
    demandAnalysis: DemandAnalysisReducer,
    products: productReducer,
    dashboard: DashboardReducer,
    onboarding: OnboardingReducer,
    userManagement: userManagementReducer,
    forecast_setup: ForecastSetupReducer,
    myCharts: myChartsReducer,
    assortment: assortmentReducer, // Existing assortment slice
    assortmentModal: assortmentModalReducer,
    inventoryInsights: inventoryInsightsReducer, // New assortment modal slice
    filter: filterReducer
  },
});

export default store;
