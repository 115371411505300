import React, { useState, useEffect } from 'react';
import "../../css/Dashboard/dashboard.css";
import "../../css/ForecastedData.css";
import AssortmentGraphMain from '../../components/AssortmentComponents/AssortmentChart/AssortmentGraphMain';
import AssortmentPlanningData from '../../components/AssortmentComponents/AssortmentTable/AssortmentPlanningData';
import InSeasonAllocationHeader from '../../components/DemandAnalysisComponents/DemandAnalysisHeader';
import { assortmentTableDataApi, assortmentPlotApi } from "../../features/Assortment/AssortmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { levelDataApi } from '../../features/Filters/FiltersSlice';

function AssortmentPage() {
    const dispatch = useDispatch();
    const { status, assortmentTableData } = useSelector(state => state.assortment);

    useEffect(() => {
        dispatch(levelDataApi())
        if (status === 'idle') {
            dispatch(assortmentTableDataApi({ filter: "Set Filter", filterName: "" }));
            dispatch(assortmentPlotApi({ filter: "Set Filter", filterName: "" }));
        }
    }, [dispatch, status]);

    const [refreshKey, setRefreshKey] = useState(0);
    const handleReset = () => {
        setRefreshKey(prevKey => prevKey + 1); // Increment key to trigger re-render
    };

    return (
        <div className='dashboard_main_container'>
            <InSeasonAllocationHeader key= {refreshKey} onReset={handleReset} />
            <AssortmentGraphMain key= {refreshKey+1} />
            <AssortmentPlanningData key= {refreshKey+2} data={assortmentTableData} />
        </div>
    );
}

export default AssortmentPage;
