import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

// Initial state for the slice
const initialState = {
  expectedStockoutData: [],
  inventoryInsightsData: [],
  opportunityLostData: [],
  loading: false,
  error: null,
};

// Helper function to build query parameters
const buildQueryParams = (state, globalFilters) => {
  let queryParams = [];

  // Loop through globalFilters dynamically
  for (const [key, value] of Object.entries(globalFilters)) {
    if (Array.isArray(value)) {
      queryParams.push(...value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item.toLowerCase())}`));
    } else {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toLowerCase())}`);
    }
  }

  if (state.genderFilter && state.genderFilter.length > 0 && state.genderFilter[0] !== "All") {
    queryParams.push(...state.genderFilter.map(item => `gender=${encodeURIComponent(item.toLowerCase())}`));
  }

  if (state.levelFilter) {
    queryParams.push(`level=${encodeURIComponent(state.levelFilter.toLowerCase())}`);
  }

  return queryParams.join('&');
};

// Thunk for fetching opportunity lost data with switch and thunkAPI
export const fetchOpportunityLost = createAsyncThunk(
  'inventoryInsights/fetchOpportunityLost',
  async (params, thunkAPI) => {
    let endpoint = "";
    let joint = "";

    switch (params.filterName) {
      case "duration":
        thunkAPI.dispatch(setFilter(params.filter.payload));
        break;
      case "brand":
        thunkAPI.dispatch(setBrandFilter(params.selectedItems));
        break;
      case "country":
        thunkAPI.dispatch(setCountryFilter(params.selectedItems));
        break;
      case "level":
        thunkAPI.dispatch(setLevelFilter(params.filter.filterType));
        break;
      case "category":
        thunkAPI.dispatch(setCategoryFilter(params.selectedItems));
        break;
      case "gender":
        thunkAPI.dispatch(setGenderFilter(params.selectedItems));
        break;
      default:
        thunkAPI.dispatch(setFilter(''));
        thunkAPI.dispatch(setBrandFilter([]));
        thunkAPI.dispatch(setCountryFilter([]));
        thunkAPI.dispatch(setCategoryFilter([]));
        thunkAPI.dispatch(setGenderFilter([]));
        thunkAPI.dispatch(setLevelFilter(''));
        break;
    }

    const state = thunkAPI.getState();
    const globalFilters = state.filter.globalFilters;
    joint = buildQueryParams(state.inventoryInsights, globalFilters);

    endpoint = `customer/chart_opportunity_lost/?${joint}&filter_type=past_2_future_2_weeks`;
    const response = await makeRequest('get', endpoint);

    return response.data;
  }
);

// Existing thunks for expected stockout and inventory insights
export const fetchExpectedStockout = createAsyncThunk(
  'inventoryInsights/fetchExpectedStockout',
  async () => {
    const response = await makeRequest('get', 'customer/expected_stockout/');
    return response.data; 
  }
);

export const fetchInventoryInsights = createAsyncThunk(
  'inventoryInsights/fetchInventoryInsights',
  async () => {
    const response = await makeRequest('get', 'customer/inventory_insights/');
    return response.data;
  }
);

const inventoryInsightsSlice = createSlice({
  name: 'inventoryInsights',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setBrandFilter: (state, action) => {
      state.brandFilter = action.payload;
    },
    setCountryFilter: (state, action) => {
      state.countryFilter = action.payload;
    },
    setLevelFilter: (state, action) => {
      state.levelFilter = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.categoryFilter = action.payload;
    },
    setGenderFilter: (state, action) => {
      state.genderFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpectedStockout.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExpectedStockout.fulfilled, (state, action) => {
        state.loading = false;
        state.expectedStockoutData = action.payload;
      })
      .addCase(fetchExpectedStockout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; 
      });

    builder
      .addCase(fetchInventoryInsights.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInventoryInsights.fulfilled, (state, action) => {
        state.loading = false;
        state.inventoryInsightsData = action.payload;
      })
      .addCase(fetchInventoryInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; 
      });

    builder
      .addCase(fetchOpportunityLost.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOpportunityLost.fulfilled, (state, action) => {
        state.loading = false;
        state.opportunityLostData = action.payload;
      })
      .addCase(fetchOpportunityLost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; 
      });
  },
});

export const { setFilter, setBrandFilter, setCountryFilter, setLevelFilter, setCategoryFilter, setGenderFilter } = inventoryInsightsSlice.actions;

export default inventoryInsightsSlice.reducer;
