import React from "react";
import { Table } from "antd";

const UserTable = ({ data, columns, pagination, rowSelection, onChange }) => {
  return (
    <Table
      rowClassName={() => "purple-row"}
      columns={columns}
      dataSource={data}
      pagination={pagination}
      rowSelection={rowSelection}
      onChange={onChange}
    />
  );
};

export default UserTable;
