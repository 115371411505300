import React, { useState, useEffect } from 'react';
import clockIcon from '../../assets/SVG/icons/DemandAnalysis/clock-icon.svg';
import { useSelector } from "react-redux";


const options = [
    // { filterType: "Current Week", payload: "current_week" },
    { filterType: "Current Year", payload: "current_year" },
    // { filterType: "Past 3 weeks", payload: "past_m_weeks&m=3" },
    { filterType: "Past 157 weeks", payload: "past_m_weeks&m=157" },
    { filterType: "Past 6 Months", payload: "past_m_months&m=6" },
    { filterType: "Future 6 Months", payload: "future_n_months&n=6" },
    { filterType: "Past 2 + 1 Future Years", payload: "past_m_future_n_years&m=2&n=1" },
    { filterType: "Past 3 + 1 Future Years", payload: "past_m_future_n_years&m=3&n=1" },
    { filterType: "Past 1 + 1 Future Years", payload: "past_m_future_n_years&m=1&n=1" },
    { filterType: "Past 1 Year", payload: "past_m_years&m=1" },
    { filterType: "Future 1 Year", payload: "future_n_years&n=1" },
    { filterType: "Set Filter", payload: "Set Filter" },
];


function CardsFilter({ isAssortment, isProduct }) {

    const { filter, brandFilter, countryFilter, categoryFilter, genderFilter } = useSelector((state) => state.demandAnalysis || "Set Filter");
    const { brandFilters, categoryFilters, genderFilters, divisionFilter, season } = useSelector((state) => state.assortment || "Set Filter");
    const { itemFilter, locationFilter } = useSelector((state) => state.products || "Set Filter");

    const [filters, setFilters] = useState({
        Brand: 'All',
        Country: 'All',
        Seasons: 'All',
        Division: 'All',
        Category: 'All',
        Gender: 'All',
        Product: 'All',
        Location: 'All',
        Duration: 'All',
    });

    const handleClick = (key) => {
        // Example update - you can implement your own logic
        // setFilters(prevFilters => ({
        //     ...prevFilters,
        //     [key]: 'SomeValue' // Update the key with some value for demonstration
        // }));
    };
    useEffect(() => {
        setFilters(prevFilters => ({
            ...prevFilters,
            Brand: (brandFilter || []).join(', '),
            Country: (countryFilter || []).join(', '),
            Category: (categoryFilter || []).join(', '),
            Gender: (genderFilter || []).join(', '),
            Division: (divisionFilter || []).join(', '),
            Seasons: (season || []).join(', '),
        }));
    }, [brandFilter, countryFilter, categoryFilter, genderFilter, divisionFilter, season]);

    useEffect(() => {
        setFilters(prevFilters => ({
            ...prevFilters,
            Brands: (brandFilters || []).join(', '),
            Country: (countryFilter || []).join(', '),
            Categories: (categoryFilters || []).join(', '),
            Genders: (genderFilters || []).join(', '),
            Division: (divisionFilter || []).join(', '),
            Seasons: (season || []).join(', '),
        }));
    }, [brandFilters, countryFilter, categoryFilters, genderFilters, divisionFilter, season]);


    useEffect(() => {
        setFilters(prevFilters => ({
            ...prevFilters,
            Product: itemFilter.join(', '),
            Location: locationFilter.join(', ')
        }));
    }, [itemFilter, locationFilter]);

    const filterData1 = [
        {
            Brand: filters.Brand,
            Country: filters.Country,
            Category: filters.Category,
            Gender: filters.Gender
        }
    ];

    const filterData2 = [
        {
            Seasons: filters.Seasons,
            Brands: filters.Brand,
            Division: filters.Division,
            Categories: filters.Category,
            Genders: filters.Gender
        }
    ];
    //   console.log(filterData2)
    const filterData3 = [
        {
            Product: filters.Product,
            Location: filters.Location,
            Duration: filters.Duration,
        }
    ];

    const currentFilterData = isAssortment ? filterData2 : (isProduct ? filterData3 : filterData1);

    return (
        <div className='forecasted_data_middle'>
            {isAssortment ? '' : <div className='forecasted_data_middle_filters'>
                <img src={clockIcon} alt="clock-icon" />
                <div>{(options.find(option => option.payload === filter)).filterType}</div>
            </div>}
            {currentFilterData.map((filter, index) => (
                <React.Fragment key={index}>
                    {Object.entries(filter).map(([key, value], entryIndex) => (
                        <React.Fragment key={key}>
                            {!(isAssortment && index === 0 && entryIndex === 0) && (
                                <div className='forecasted_data_vertical_line'></div>
                            )}
                            <div onClick={() => handleClick(key)} style={{ cursor: 'pointer' }}>
                                {key}: {filters[key]?.length > 0 && filters[key] !== 'All' ? filters[key] : 'All'}
                            </div>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}

export default CardsFilter;
